import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocationName } from '../../../shared/models/location-name';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(private utilityService: UtilityService) {}

  locationsEndpoint = environment.scheduleApiUrl + '/api/v1/LocationNames';

  getLocations(): Observable<LocationName[]> {
    return this.utilityService.getRequest<LocationName[]>(this.locationsEndpoint);
  }
}
