<div class="demo-header-container">
  <div class="demo-header">
    <h4>Demographics</h4>
    <button id="btnEdit" data-test="btnEditDemographics" mat-stroked-button (click)="editEvent.emit()">Edit</button>
  </div>
</div>

<div class="demo-container">
  @if (demographics !== null) {
    <div class="flex flex-col gap-20">
      <div class="row-container">
        <div class="flex align-start gap-8">
          <mat-icon fontIcon="group" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Marital Status</p>
            @if (demographics.maritalStatus !== null && demographics.maritalStatus !== '') {
              <p id="maritalStatus" class="fw-500" data-test="strMaritalStatus">
                {{ demographics.maritalStatus }}
              </p>
            } @else {
              <p id="maritalStatusNA" class="fw-500" data-test="strMaritalStatusNA">Not Available</p>
            }
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="emoji_language" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Primary Language</p>
            @if (demographics.primaryLanguage !== null && demographics.primaryLanguage !== '') {
              <p id="primaryLanguage" class="fw-500" data-test="strPrimaryLanguage">
                {{ demographics.primaryLanguage }}
              </p>
            } @else {
              <p id="primaryLanguageNA" class="fw-500" data-test="strPrimaryLanguageNA">Not Available</p>
            }
          </div>
        </div>
      </div>

      <div class="row-container">
        <div class="flex align-start gap-8">
          <mat-icon fontIcon="diversity_2" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Race</p>
            @if (demographics.race !== null && demographics.race !== '') {
              <p id="race" class="fw-500" data-test="strRace">
                {{ demographics.race }}
              </p>
            } @else {
              <p id="raceNA" class="fw-500" data-test="strRaceNA">Not Available</p>
            }
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="church" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Religion</p>
            @if (demographics.religion !== null && demographics.religion !== '') {
              <p id="religion" class="fw-500" data-test="strReligion">
                {{ demographics.religion }}
              </p>
            } @else {
              <p id="religionNA" class="fw-500" data-test="strReligionNA">Not Available</p>
            }
          </div>
        </div>
      </div>

      <div class="flex align-start gap-8">
        <mat-icon fontIcon="public" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
        <div class="flex flex-col gap-4">
          <p class="mat-label-medium">Ethnicity</p>
          @if (demographics.ethnicity !== null && demographics.ethnicity !== '') {
            <p id="ethnicity" class="fw-500" data-test="strEthnicity">
              {{ demographics.ethnicity }}
            </p>
          } @else {
            <p id="ethnicityNA" class="fw-500" data-test="strEthnicityNA">Not Available</p>
          }
        </div>
      </div>
    </div>
  }
</div>
