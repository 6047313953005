import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { PatientInformationService } from '../../services/patient-information/patient-information.service';
import { UsStatesService } from '../../services/us-states/us-states.service';
import { EditPatientInformationComponent } from '../../ui/edit-patient-information/edit-patient-information.component';
import {
  closeEditDialog,
  getPatientInformation,
  getPatientInformationFailure,
  getPatientInformationSuccess,
  getUsStates,
  getUsStatesFailure,
  getUsStatesSuccess,
  openEditPatientInformationDialog,
  savePatientInformation,
  savePatientInformationFailure,
  savePatientInformationSuccess,
} from '../actions/patient-information.actions';
import { selectProfile } from '../selectors/patient-information.selectors';

@Injectable()
export class PatientInformationEffects {
  constructor(
    private actions$: Actions,
    private patientInformationService: PatientInformationService,
    private usStatesService: UsStatesService,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private store: Store
  ) {}

  getUsStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUsStates),
      mergeMap(() => {
        return this.usStatesService.getUsStates().pipe(
          map(usStates => {
            return getUsStatesSuccess({ usStates });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getUsStatesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getPatientInformation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPatientInformation, getPatientInformation),
      mergeMap(() => {
        return this.patientInformationService.getPatientProfile().pipe(
          map(profile => {
            return getPatientInformationSuccess({ profile });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPatientInformationFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  savePatientProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePatientInformation),
      mergeMap(action => {
        return this.patientInformationService.postPatientProfile(action.profile).pipe(
          map(profile => {
            return savePatientInformationSuccess({ profile });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(savePatientInformationFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  openEditPatientInformationDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(openEditPatientInformationDialog),
        concatLatestFrom(() => [this.store.select(selectProfile)]),
        tap(([, patientProfile]) => {
          this.dialog.open(EditPatientInformationComponent, {
            id: 'edit-profile-dialog',
            data: {
              patientProfile,
            },
            disableClose: true,
            autoFocus: false,
            minWidth: '800px',
            minHeight: '250px',
          });
        })
      );
    },
    { dispatch: false }
  );

  closeEditDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(savePatientInformationSuccess, closeEditDialog),
        tap(() => {
          this.dialog.closeAll();
        })
      );
    },
    { dispatch: false }
  );
}
