<div class="info-header-container">
  <div class="info-header">
    <h4>Personal Information</h4>
    <button id="btnEdit" data-test="btnEditPatientInfo" mat-stroked-button (click)="editEvent.emit()">Edit</button>
  </div>
</div>

<div class="info-container">
  @if (profile !== null) {
    <div class="flex flex-col gap-15">
      <div>
        <h4 id="patientName" data-test="strFirstLastName">
          {{ profile.firstName }}
          @if (profile.preferredFirstName !== null && profile.preferredFirstName !== '') {
            ({{ profile.preferredFirstName }})
          }
          {{ profile.lastName }}
        </h4>
        @if (profile.preferredGenderPronouns !== null && profile.preferredGenderPronouns !== '') {
          <p id="preferredPronouns" data-test="strPreferredPronouns" class="fs-14 fw-400 mt-4">
            {{ profile.preferredGenderPronouns }}
          </p>
        }
      </div>

      <div class="row-container">
        <div class="flex align-start gap-8">
          <mat-icon fontIcon="stethoscope" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Primary Care Physician</p>
            @if (profile.primaryCareProviderFullName !== null && profile.primaryCareProviderFullName !== '') {
              <p id="primaryCare" class="fw-500" data-test="strPrimaryCareProviderFullName">
                {{ profile.primaryCareProviderFullName }}
              </p>
            } @else {
              <p id="primaryCareNA" class="fw-500" data-test="strPrimaryCareProviderFullNameNA">Not Available</p>
            }
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="calendar_month" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">DOB</p>
            <p id="dateOfBirth" class="fw-500" data-test="strDateOfBirth">
              {{ profile.dateOfBirth | date: 'M/d/yyyy' }}
            </p>
          </div>
        </div>
      </div>

      <div class="row-container">
        <div class="flex align-start gap-8">
          <mat-icon fontIcon="email" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Email</p>
            @if (profile.emailAddress !== null && profile.emailAddress !== '') {
              <p id="email" class="fw-500" data-test="strEmailAddress">{{ profile.emailAddress }}</p>
            } @else {
              <p id="emailNA" class="fw-500" data-test="strEmailAddressNA">Not Available</p>
            }
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="clinical_notes" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">SSN</p>
            @if (profile.socialSecurityNumber !== null && profile.socialSecurityNumber !== '') {
              <p id="ssn" class="fw-500" data-test="strSocialSecurityNumber">
                {{ profile.socialSecurityNumber | mask: '000-00-0000' }}
              </p>
            } @else {
              <p id="ssnNA" class="fw-500" data-test="strSocialSecurityNumberNA">Not Available</p>
            }
          </div>
        </div>
      </div>

      <div class="row-container">
        <div class="flex align-start gap-8">
          <mat-icon fontIcon="location_on" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Residential Address</p>
            <p id="resAddress1" class="fw-500" data-test="strResidentialAddress1">
              {{ profile.residentialAddress.address1 }}
            </p>
            @if (profile.residentialAddress.address2 !== null && profile.residentialAddress.address2 !== '') {
              <p id="resAddress2" class="fw-500" data-test="strResidentialAddress2">
                {{ profile.residentialAddress.address2 }}
              </p>
            }
            <p id="resCityStateZip" class="fw-500" data-test="strResidentialCityStateZip">
              {{ profile.residentialAddress.city }}, {{ profile.residentialAddress.stateAbbreviation }}
              {{ profile.residentialAddress.postalCode }}
            </p>
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="smartphone" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Mobile</p>
            @if (profile.mobilePhoneNumber !== null && profile.mobilePhoneNumber !== '') {
              <p id="mobilePhone" class="fw-500" data-test="strMobilePhoneNumber">
                {{ profile.mobilePhoneNumber | mask: '(000) 000-0000' }}
              </p>
            } @else {
              <p id="mobilePhoneNA" class="fw-500" data-test="strMobilePhoneNumberNA">Not Available</p>
            }
          </div>
        </div>
      </div>

      <div class="row-container">
        <div class="flex align-start gap-8">
          <mat-icon fontIcon="markunread_mailbox" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Mailing Address</p>
            @if (profile.mailingAddress !== null) {
              <p id="mailAddress1" class="fw-500" data-test="strMailingAddress1">
                {{ profile.mailingAddress.address1 }}
              </p>
              @if (profile.mailingAddress.address2 !== null && profile.mailingAddress.address2 !== '') {
                <p id="mailAddress2" class="fw-500" data-test="strMailingAddress2">
                  {{ profile.mailingAddress.address2 }}
                </p>
              }
              <p id="mailCityStateZip" class="fw-500" data-test="strMailingCityStateZip">
                {{ profile.mailingAddress.city }}, {{ profile.mailingAddress.stateAbbreviation }}
                {{ profile.mailingAddress.postalCode }}
              </p>
            } @else {
              <p id="mailNA" class="fw-500" data-test="strMailingAddressNA">Not Available</p>
            }
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="phone" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Home</p>
            @if (profile.homePhoneNumber !== null && profile.homePhoneNumber !== '') {
              <p id="homePhone" class="fw-500" data-test="strHomePhoneNumber">
                {{ profile.homePhoneNumber | mask: '(000) 000-0000' }}
              </p>
            } @else {
              <p id="homePhoneNA" class="fw-500" data-test="strHomePhoneNumberNA">Not Available</p>
            }
          </div>
        </div>
      </div>
    </div>
  }
</div>
