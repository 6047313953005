import { createReducer, on } from '@ngrx/store';

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LocationName } from '../../../shared/models/location-name';
import { SchedulingTips } from '../../models/scheduling-tips';
import * as appActions from '../actions/app.actions';

export const appFeatureKey = 'app';

export interface AppState extends EntityState<LocationName> {
  isLoading: boolean;
  error: any;
  selectedLocationNameId: number;
  schedulingTips: SchedulingTips | null;
}

export const appAdapter = createEntityAdapter<LocationName>({
  selectId: location => location.locationId,
});

export const initialState = appAdapter.getInitialState({
  isLoading: false,
  error: null,
  selectedLocationNameId: -1,
  schedulingTips: null,
});

export const appReducer = createReducer<AppState>(
  initialState,

  on(appActions.getLocations, (state): AppState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),

  on(appActions.getLocations, (state): AppState => appAdapter.removeAll(state)),

  on(appActions.getLocationsSuccess, (state, { locations }): AppState => appAdapter.addMany(locations, state)),

  on(appActions.getLocationsSuccess, (state): AppState => {
    return {
      ...state,
      isLoading: false,
      error: null,
      selectedLocationNameId: state.entities[1] === undefined ? -1 : state.entities[1].locationId, // this is temporary until we get business requirements for how we auto select the initial location for a user
    };
  }),

  on(appActions.getLocationsFailure, (state, { error }): AppState => {
    return {
      ...state,
      isLoading: false,
      error,
    };
  }),

  on(appActions.selectLocationNameId, (state, { locationNameId }): AppState => {
    return {
      ...state,
      selectedLocationNameId: locationNameId,
    };
  }),

  on(appActions.getSchedulingTips, (state): AppState => {
    return {
      ...state,
      isLoading: true,
      schedulingTips: null,
      error: null,
    };
  }),

  on(appActions.getSchedulingTipsSuccess, (state, { schedulingTips }): AppState => {
    return {
      ...state,
      isLoading: false,
      schedulingTips,
      error: null,
    };
  }),

  on(appActions.getSchedulingTipsFailure, (state, { error }): AppState => {
    return {
      ...state,
      isLoading: false,
      error,
    };
  })
);
