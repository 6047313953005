import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { getPatientInformation } from '../../../patient-information/store/actions/patient-information.actions';
import { EmploymentStatusTypesService } from '../../services/employment-status-types/employment-status-types.service';
import { PatientEmploymentService } from '../../services/patient-employment/patient-employment.service';
import {
  closeEditDialog,
  getEmploymentStatusTypes,
  getEmploymentStatusTypesFailure,
  getEmploymentStatusTypesSuccess,
  getPatientEmployment,
  getPatientEmploymentFailure,
  getPatientEmploymentSuccess,
  openEditPatientEmploymentDialog,
  savePatientEmployment,
  savePatientEmploymentFailure,
  savePatientEmploymentSuccess,
} from '../actions/patient-employment.actions';
import { selectEmployment } from '../selectors/patient-employment.selectors';
import { EditPatientEmploymentComponent } from '../../ui/edit-patient-employment/edit-patient-employment.component';

@Injectable()
export class PatientEmploymentEffects {
  constructor(
    private actions$: Actions,
    private employmentService: PatientEmploymentService,
    private employmentStatusTypesService: EmploymentStatusTypesService,
    private store: Store,
    private logger: NGXLogger,
    private dialog: MatDialog
  ) {}

  getPatientEmployment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPatientEmployment, getPatientInformation),
      mergeMap(() => {
        return this.employmentService.getPatientEmployment().pipe(
          map(employment => {
            return getPatientEmploymentSuccess({ employment });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPatientEmploymentFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getEmploymentStatusTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getEmploymentStatusTypes),
      mergeMap(() => {
        return this.employmentStatusTypesService.getEmploymentStatusTypes().pipe(
          map(employmentStatusTypes => {
            return getEmploymentStatusTypesSuccess({ employmentStatusTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getEmploymentStatusTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  savePatientEmployment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePatientEmployment),
      mergeMap(action => {
        return this.employmentService.postPatientEmployment(action.employment).pipe(
          map(employment => {
            return savePatientEmploymentSuccess({ employment });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(savePatientEmploymentFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  openEditPatientEmploymentDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(openEditPatientEmploymentDialog),
        concatLatestFrom(() => [this.store.select(selectEmployment)]),
        tap(([, patientEmployment]) => {
          this.dialog.open(EditPatientEmploymentComponent, {
            id: 'edit-patient-employment-dialog',
            data: {
              patientEmployment,
            },
            disableClose: true,
            autoFocus: false,
            width: '600px',
            height: '350px',
          });
        })
      );
    },
    { dispatch: false }
  );

  closeEditPatientEmploymentDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(savePatientEmploymentSuccess, closeEditDialog),
        tap(() => {
          this.dialog.closeAll();
        })
      );
    },
    { dispatch: false }
  );
}
