import { createAction, props } from '@ngrx/store';
import { AdministrativeScan } from '../../models/administrative-scan';

export const getAdministrativeScans = createAction('[Administrative Scan] Get Administrative Scans');

export const getAdministrativeScansSuccess = createAction(
  '[Administrative Scan] Get Administrative Scans Success',
  props<{ administrativeScans: AdministrativeScan[] }>()
);

export const getAdministrativeScansFailure = createAction(
  '[Administrative Scan] Get Administrative Scans Failure',
  props<{ error: any }>()
);
