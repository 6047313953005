import { createAction, props } from '@ngrx/store';
import { Appointment } from '../../models/appointment';

export const getAppointments = createAction('[Appointment] Get Appointments');

export const getAppointmentsSuccess = createAction(
  '[Appointment] Get Appointments Success',
  props<{ appointments: Appointment[] }>()
);

export const getAppointmentsFailure = createAction('[Appointment] Get Appointments Failure', props<{ error: any }>());
