<div class="edit-employment-header-container">
  <div class="edit-employment-header">
    <div class="flex justify-center align-center flex-1">
      <h4>Edit Patient Employment</h4>
    </div>
    <mat-icon
      id="btnClose"
      data-test="btnCloseEditEmployment"
      fontIcon="close"
      class="material-symbols-outlined color-neutral cursor"
      (click)="cancel()"></mat-icon>
  </div>
</div>

<ng-container *ngrxLet="{ employmentStatusTypes: employmentStatusTypes$, isSaving: isSaving$ } as vm">
  <app-progress-spinner
    *ngIf="patientEmployment === null || vm.employmentStatusTypes === null; else editEmployerForm"
    data-test="spinnerEditPatientContacts"></app-progress-spinner>

  <ng-template #editEmployerForm>
    <app-progress-spinner-overlay
      [displayProgressSpinner]="vm.isSaving === true"
      data-test="spinnerSavePatientEmployment"></app-progress-spinner-overlay>

    <div id="editEmploymentForm" class="edit-employment-container">
      <form [formGroup]="employmentForm" (submit)="save()">
        <div class="form-button-container">
          <div class="form-container">
            <div class="flex gap-20">
              <div class="form-field flex-1">
                <mat-label>Employment Status <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="employmentStatus" data-test="selEmploymentStatusTypes">
                    @for (employmentStatus of employmentStatusTypes$ | async; track employmentStatus) {
                      <mat-option [value]="employmentStatus.employmentStatusTypeId">{{
                        employmentStatus.description
                      }}</mat-option>
                    }
                  </mat-select>
                  @if (employmentForm.controls['employmentStatus'].hasError('required')) {
                    <mat-error id="errEmployerStatusRequired" data-test="matErrEmployerStatusRequired"
                      >Required</mat-error
                    >
                  }
                </mat-form-field>
              </div>

              <div class="form-field flex-1">
                <mat-label>Employer Name</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input matInput type="text" formControlName="employerName" data-test="iptEmployerName" />
                  @if (employmentForm.controls['employerName'].hasError('maxlength')) {
                    <mat-error id="errEmployerNameMax" data-test="matErrEmployerNameMax"
                      >Must be 100 characters or less</mat-error
                    >
                  }
                </mat-form-field>
              </div>
            </div>

            <div class="flex gap-20">
              <div class="form-field flex-1">
                <mat-label>Employer Phone</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    id="employerPhone"
                    type="tel"
                    matInput
                    formControlName="employerPhone"
                    data-test="iptEmployerPhone"
                    mask="(000) 000-0000" />
                  @if (employmentForm.controls['employerPhone'].hasError('mask')) {
                    <mat-error id="errEmployerPhoneLength" data-test="matErrEmployerPhoneLength"
                      >Must be 10 digits</mat-error
                    >
                  }
                </mat-form-field>
              </div>

              <div class="form-field flex-1">
                <mat-label>Work Phone</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    id="workPhone"
                    type="tel"
                    matInput
                    formControlName="workPhone"
                    data-test="iptWorkPhone"
                    mask="(000) 000-0000" />
                  @if (employmentForm.controls['workPhone'].hasError('mask')) {
                    <mat-error id="errWorkPhoneLength" data-test="matErrWorkPhoneLength">Must be 10 digits</mat-error>
                  }
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="button-container">
            <button
              id="btnCancel"
              data-test="btnCancelEditEmployment"
              mat-stroked-button
              type="button"
              class="cancel-button"
              (click)="cancel()">
              Cancel
            </button>
            <button
              id="btnSave"
              data-test="btnSaveEditEmployment"
              mat-raised-button
              type="submit"
              class="save-button"
              color="primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</ng-container>
