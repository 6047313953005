import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as appReducer from '../reducer/app.reducer';
import { AppState, appFeatureKey } from '../reducer/app.reducer';

export const selectAppFeature = createFeatureSelector<AppState>(appFeatureKey);

export const selectIsLoading = createSelector(selectAppFeature, (state: AppState) => state.isLoading);

const { selectAll } = appReducer.appAdapter.getSelectors();

export const selectLocations = createSelector(selectAppFeature, selectAll);

export const selectSelectedLocationNameId = createSelector(
  selectAppFeature,
  (state: AppState) => state.selectedLocationNameId
);

export const selectSchedulingTips = createSelector(selectAppFeature, (state: AppState) => state.schedulingTips);

export const selectError = createSelector(selectAppFeature, (state: AppState) => state.error);
