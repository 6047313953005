/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';
import { PatientInformationState, patientInformationFeatureKey } from '../reducer/patient-information.reducer';

export const patientInformationFeatureSelector =
  createFeatureSelector<PatientInformationState>(patientInformationFeatureKey);

export const selectIsLoading = createSelector(patientInformationFeatureSelector, state => state.isLoading);

export const selectError = createSelector(patientInformationFeatureSelector, state =>
  state.error === null ? null : environment.errorMessage
);

export const selectIsSaving = createSelector(patientInformationFeatureSelector, state => state.isSaving);

export const selectProfile = createSelector(patientInformationFeatureSelector, state => state.profile);

export const selectUsStates = createSelector(patientInformationFeatureSelector, state => state.usStates);
