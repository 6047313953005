import { createAction, props } from '@ngrx/store';
import { IPatientContacts } from '../../models/patient-contacts';
import { PatientContactRelationshipType } from '../../models/types/patient-contact-relationship-types';

export const getPatientContacts = createAction('[Patient] Get Patient Contacts');

export const getPatientContactsSuccess = createAction(
  '[Patient] Get Patient Contacts Success',
  props<{ contacts: IPatientContacts }>()
);

export const getPatientContactsFailure = createAction(
  '[Patient] Get Patient Contacts Failure',
  props<{ error: any }>()
);

export const getPatientContactRelationshipTypes = createAction('[Patient] Get Patient Contact Relationship Types');

export const getPatientContactRelationshipTypesSuccess = createAction(
  '[Patient] Get Patient Contact Relationship Types Success',
  props<{ patientContactRelationshipTypes: PatientContactRelationshipType[] }>()
);

export const getPatientContactRelationshipTypesFailure = createAction(
  '[Patient] Get Patient Contact Relationship Types Failure',
  props<{ error: any }>()
);

export const savePatientContacts = createAction(
  '[Patient] Save Patient Contacts',
  props<{ contacts: IPatientContacts }>()
);

export const savePatientContactsSuccess = createAction(
  '[Patient] Save Patient Contacts Success',
  props<{ contacts: IPatientContacts }>()
);

export const savePatientContactsFailure = createAction(
  '[Patient] Save Patient Contacts Failure',
  props<{ error: any }>()
);

export const openEditPatientContactsDialog = createAction('[Patient] Open Edit Patient Contacts Dialog');

export const closeEditDialog = createAction('[Patient] Close Edit Dialog');
