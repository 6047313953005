<div class="edit-demographics-header-container">
  <div class="edit-demographics-header">
    <div class="flex justify-center align-center flex-1">
      <h4>Edit Demographics</h4>
    </div>
    <mat-icon
      id="btnClose"
      data-test="btnCloseEditDemographics"
      fontIcon="close"
      class="material-symbols-outlined color-neutral cursor"
      (click)="cancel()"></mat-icon>
  </div>
</div>

<ng-container
  *ngrxLet="{
    maritalStatusTypes: maritalStatusTypes$,
    primaryLanguageTypes: primaryLanguageTypes$,
    raceTypes: raceTypes$,
    ethnicityTypes: ethnicityTypes$,
    religionTypes: religionTypes$,
    isSaving: isSaving$,
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.maritalStatusTypes === null ||
        vm.primaryLanguageTypes === null ||
        vm.raceTypes === null ||
        vm.ethnicityTypes === null ||
        vm.religionTypes === null ||
        patientDemographics === null;
      else editDemographics
    "
    data-test="spinnerEditPatientDemographics"></app-progress-spinner>

  <ng-template #editDemographics>
    <app-progress-spinner-overlay
      [displayProgressSpinner]="vm.isSaving"
      data-test="spinnerSavePatientDemographics"></app-progress-spinner-overlay>

    <div id="editDemographicsForm" class="edit-demographics-container">
      <form [formGroup]="demographicsForm" (submit)="save()">
        <div class="form-button-container">
          <div class="form-container">
            <div class="flex gap-20">
              <div class="form-field flex-1">
                <mat-label>Marital Status</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="maritalStatus" data-test="selMaritalStatus">
                    @for (maritalStatus of maritalStatusTypes$ | async; track maritalStatus) {
                      <mat-option [value]="maritalStatus.maritalStatusTypeId">{{
                        maritalStatus.description
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field flex-1">
                <mat-label>Primary Language</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="primaryLanguage" data-test="selPrimaryLanguage">
                    @for (primaryLanguage of primaryLanguageTypes$ | async; track primaryLanguage) {
                      <mat-option [value]="primaryLanguage.primaryLanguageTypeId">{{
                        primaryLanguage.description
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="flex gap-20">
              <div class="form-field flex-1">
                <mat-label>Race</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="race" data-test="selRace">
                    @for (race of raceTypes$ | async; track race) {
                      <mat-option [value]="race.raceTypeId">{{ race.description }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field flex-1">
                <mat-label>Religion</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="religion" data-test="selReligion">
                    @for (religion of religionTypes$ | async; track religion) {
                      <mat-option [value]="religion.religionTypeId">{{ religion.description }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="flex gap-20">
              <div class="form-field flex-1">
                <mat-label>Ethnicity</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="ethnicity" data-test="selEthnicity">
                    @for (ethnicity of ethnicityTypes$ | async; track ethnicity) {
                      <mat-option [value]="ethnicity.ethnicityTypeId">{{ ethnicity.description }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field flex-1"></div>
            </div>
          </div>

          <div class="button-container">
            <button
              id="btnCancel"
              data-test="btnCancelEditDemographics"
              mat-stroked-button
              type="button"
              class="cancel-button"
              (click)="cancel()">
              Cancel
            </button>
            <button
              id="btnSave"
              data-test="btnSaveEditDemographics"
              mat-raised-button
              type="submit"
              class="save-button"
              color="primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</ng-container>
