import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { getPatientInformation } from '../../../patient-information/store/actions/patient-information.actions';
import { EmploymentStatusTypesService } from '../../services/employment-status-types/employment-status-types.service';
import { PatientEmploymentService } from '../../services/patient-employment/patient-employment.service';
import {
  getEmploymentStatusTypes,
  getEmploymentStatusTypesFailure,
  getEmploymentStatusTypesSuccess,
  getPatientEmployment,
  getPatientEmploymentFailure,
  getPatientEmploymentSuccess,
  savePatientEmployment,
  savePatientEmploymentFailure,
  savePatientEmploymentSuccess,
} from '../actions/patient-employment.actions';

@Injectable()
export class PatientEmploymentEffects {
  constructor(
    private actions$: Actions,
    private employmentService: PatientEmploymentService,
    private employmentStatusTypesService: EmploymentStatusTypesService,
    private logger: NGXLogger
  ) {}

  getPatientEmployment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPatientEmployment, getPatientInformation),
      mergeMap(() => {
        return this.employmentService.getPatientEmployment().pipe(
          map(employment => {
            return getPatientEmploymentSuccess({ employment });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPatientEmploymentFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getEmploymentStatusTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getEmploymentStatusTypes),
      mergeMap(() => {
        return this.employmentStatusTypesService.getEmploymentStatusTypes().pipe(
          map(employmentStatusTypes => {
            return getEmploymentStatusTypesSuccess({ employmentStatusTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getEmploymentStatusTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  savePatientEmployment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePatientEmployment),
      mergeMap(action => {
        return this.employmentService.postPatientEmployment(action.employment).pipe(
          map(employment => {
            return savePatientEmploymentSuccess({ employment });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(savePatientEmploymentFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });
}
