import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { PatientContactRelationshipType } from '../../models/types/patient-contact-relationship-types';

@Injectable({
  providedIn: 'root',
})
export class PatientContactRelationshipTypesService {
  constructor(private utilityService: UtilityService) {}

  patientContactRelationshipTypesEndpoint =
    environment.scheduleApiUrl + 'patientApi/Admin/PatientContactRelationshipTypes';

  getPatientContactRelationshipTypes(): Observable<PatientContactRelationshipType[]> {
    return this.utilityService.getRequest<PatientContactRelationshipType[]>(
      this.patientContactRelationshipTypesEndpoint
    );
  }
}
