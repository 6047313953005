import { createReducer, on } from '@ngrx/store';
import { PatientEmployment } from '../../models/patient-employment';
import { EmploymentStatusType } from '../../models/types/employment-status-type';

import * as patientEmploymentActions from '../actions/patient-employment.actions';

export const patientEmploymentFeatureKey = 'patientEmployment';

export interface PatientEmploymentState {
  isLoading: boolean;
  error: any;
  isSaving: boolean;
  employment: PatientEmployment | null;
  employmentStatusTypes: EmploymentStatusType[] | null;
}

export const initialState: PatientEmploymentState = {
  isLoading: false,
  error: null,
  isSaving: false,
  employment: null,
  employmentStatusTypes: null,
};

export const patientEmploymentReducer = createReducer<PatientEmploymentState>(
  initialState,

  on(patientEmploymentActions.getPatientEmployment, (state): PatientEmploymentState => {
    return {
      ...state,
      isLoading: true,
      employment: null,
    };
  }),

  on(patientEmploymentActions.getPatientEmploymentSuccess, (state, { employment }): PatientEmploymentState => {
    return {
      ...state,
      employment,
      isLoading: false,
      error: null,
    };
  }),

  on(patientEmploymentActions.getPatientEmploymentFailure, (state, { error }): PatientEmploymentState => {
    return {
      ...state,
      error,
      isLoading: false,
      employment: null,
    };
  }),

  on(patientEmploymentActions.getEmploymentStatusTypesFailure, (state, { error }): PatientEmploymentState => {
    return {
      ...state,
      isLoading: false,
      error,
      employmentStatusTypes: null,
    };
  }),

  on(patientEmploymentActions.getEmploymentStatusTypes, (state): PatientEmploymentState => {
    return {
      ...state,
      isLoading: true,
      employmentStatusTypes: null,
    };
  }),

  on(
    patientEmploymentActions.getEmploymentStatusTypesSuccess,
    (state, { employmentStatusTypes }): PatientEmploymentState => {
      return {
        ...state,
        isLoading: false,
        employmentStatusTypes,
      };
    }
  ),

  on(patientEmploymentActions.savePatientEmployment, (state): PatientEmploymentState => {
    return {
      ...state,
      error: null,
      isSaving: true,
    };
  }),

  on(patientEmploymentActions.savePatientEmploymentSuccess, (state, { employment }): PatientEmploymentState => {
    return {
      ...state,
      error: null,
      isSaving: false,
      employment,
    };
  }),

  on(patientEmploymentActions.savePatientEmploymentFailure, (state, { error }): PatientEmploymentState => {
    return {
      ...state,
      error,
      isSaving: false,
    };
  }),

  on(patientEmploymentActions.getEmploymentStatusTypesFailure, (state, { error }): PatientEmploymentState => {
    return {
      ...state,
      isLoading: false,
      error,
      employmentStatusTypes: null,
    };
  })
);
