import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { getPatientInformation } from '../../../patient-information/store/actions/patient-information.actions';
import { PatientContactRelationshipTypesService } from '../../services/patient-contact-relationship-types/patient-contact-relationship-types.service';
import { PatientContactsService } from '../../services/patient-contacts/patient-contacts.service';
import {
  getPatientContactRelationshipTypes,
  getPatientContactRelationshipTypesFailure,
  getPatientContactRelationshipTypesSuccess,
  getPatientContacts,
  getPatientContactsFailure,
  getPatientContactsSuccess,
  savePatientContacts,
  savePatientContactsFailure,
  savePatientContactsSuccess,
} from '../actions/patient-contacts.actions';

@Injectable()
export class PatientContactsEffects {
  constructor(
    private actions$: Actions,
    private contactsService: PatientContactsService,
    private patientContactRelationshipTypesService: PatientContactRelationshipTypesService,
    private logger: NGXLogger
  ) {}

  getPatientContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPatientContacts, getPatientInformation),
      mergeMap(() => {
        return this.contactsService.getPatientContacts().pipe(
          map(contacts => {
            return getPatientContactsSuccess({ contacts });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPatientContactsFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getPatientContactRelationshipTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPatientContactRelationshipTypes),
      mergeMap(() => {
        return this.patientContactRelationshipTypesService.getPatientContactRelationshipTypes().pipe(
          map(patientContactRelationshipTypes => {
            return getPatientContactRelationshipTypesSuccess({ patientContactRelationshipTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPatientContactRelationshipTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  savePatientContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePatientContacts),
      mergeMap(action => {
        return this.contactsService.postPatientContacts(action.contacts).pipe(
          map(contacts => {
            return savePatientContactsSuccess({ contacts });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(savePatientContactsFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });
}
