<div class="appointment-header-container">
  <div class="appointment-header">
    <h4>Appointments</h4>
    @if (appointments.length !== 0) {
      <button id="btnView" data-test="btnViewAppointments" mat-stroked-button>View</button>
    }
  </div>
</div>

@if (appointments.length > 0) {
  <div class="ml-10 mr-10 mt-20">
    @for (appointment of appointments; track appointment) {
      <div id="apptContainer" class="flex flex-col justify-start mb-15">
        <div id="reasonAndDate" class="flex justify-between align-center">
          <div id="reasonContainer" class="flex">
            <p class="fw-600" id="apptReason">{{ appointment.appointmentReason }}</p>
          </div>
          <div id="dateContainer" class="flex">
            <p id="apptDate" class="appointment-date fw-600">
              {{ appointment.appointmentDate | date: 'M/d/YY' }}
            </p>
          </div>
        </div>
        <div id="providerAndLocationContainer" class="flex gap-4 mt-4 ml-20">
          <p id="providerAndLocation" class="fw-400 fs-14">
            {{ appointment.appointmentProvider }} - {{ appointment.appointmentLocation }}
          </p>
        </div>
      </div>
    }
  </div>
} @else {
  <div class="flex justify-center mt-90">
    <p id="noApptMessage" class="fs-18">No appointments to display</p>
  </div>
}
