import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { IPatientContacts, PatientContacts } from '../../models/patient-contacts';

@Injectable({
  providedIn: 'root',
})
export class PatientContactsService {
  constructor(private utilityService: UtilityService) {}

  patientContactsEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/PatientContacts';

  getPatientContacts(): Observable<IPatientContacts> {
    const url = this.patientContactsEndpoint + '?patientId=28';

    return this.utilityService.getRequest<IPatientContacts>(url);
  }

  postPatientContacts(contacts: PatientContacts): Observable<PatientContacts> {
    const body = {
      patientId: 28,
      primaryContact:
        contacts.primaryContact === null
          ? null
          : {
              patientContactRelationshipTypeId: contacts.primaryContact.patientContactRelationshipTypeId,
              firstName: contacts.primaryContact.firstName,
              lastName: contacts.primaryContact.lastName,
              homePhoneNumber: contacts.primaryContact.homePhoneNumber,
              mobilePhoneNumber: contacts.primaryContact.mobilePhoneNumber,
            },
      secondaryContact:
        contacts.secondaryContact === null
          ? null
          : {
              patientContactRelationshipTypeId: contacts.secondaryContact.patientContactRelationshipTypeId,
              firstName: contacts.secondaryContact.firstName,
              lastName: contacts.secondaryContact.lastName,
              homePhoneNumber: contacts.secondaryContact.homePhoneNumber,
              mobilePhoneNumber: contacts.secondaryContact.mobilePhoneNumber,
            },
      version: contacts.version,
      modifiedByUserId: 1,
    };

    return this.utilityService.postRequest<PatientContacts>(this.patientContactsEndpoint, body);
  }
}
