export interface IAddress {
  address1: string;
  address2: string | null;
  city: string;
  stateAbbreviation: string;
  postalCode: string;
}

export class Address implements IAddress {
  address1: string;
  address2: string | null;
  city: string;
  stateAbbreviation: string;
  postalCode: string;

  constructor() {
    (this.address1 = ''),
      (this.address2 = null),
      (this.city = ''),
      (this.stateAbbreviation = ''),
      (this.postalCode = '');
  }
}
