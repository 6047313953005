import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { provideNgxMask } from 'ngx-mask';
import { Appointment } from '../../models/appointment';

@Component({
  selector: 'app-appointment-ui',
  standalone: true,
  imports: [MatButtonModule, DatePipe],
  providers: [provideNgxMask()],
  templateUrl: './appointment-ui.component.html',
  styleUrl: './appointment-ui.component.scss',
})
export class AppointmentUiComponent {
  @Input() appointments: Appointment[] = [];
}
