import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { UtilityService } from '../../../../../../core/services/utility/utility.service';
import { RaceType } from '../../../models/types/race-type';

@Injectable({
  providedIn: 'root',
})
export class RaceTypesService {
  constructor(private utilityService: UtilityService) {}

  raceTypesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/RaceTypes';

  getRaceTypes(): Observable<RaceType[]> {
    return this.utilityService.getRequest<RaceType[]>(this.raceTypesEndpoint);
  }
}
