import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { EmploymentStatusType } from '../../models/types/employment-status-type';

@Injectable({
  providedIn: 'root',
})
export class EmploymentStatusTypesService {
  constructor(private utilityService: UtilityService) {}

  employmentStatusTypesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/EmploymentStatusTypes';

  getEmploymentStatusTypes(): Observable<EmploymentStatusType[]> {
    return this.utilityService.getRequest<EmploymentStatusType[]>(this.employmentStatusTypesEndpoint);
  }
}
