<ng-container *ngrxLet="{ administrativeScans: administrativeScans$ } as vm">
  <app-progress-spinner
    *ngIf="vm.administrativeScans.length === 0; else administrativeScanDiv"
    data-test="spinnerAdministrativeScans"
    class="loading"></app-progress-spinner>

  <ng-template #administrativeScanDiv>
    <app-administrative-scan-ui [administrativeScans]="vm.administrativeScans"></app-administrative-scan-ui>
  </ng-template>
</ng-container>
