import moment from 'moment';

export function isFutureDate(dateStr: string | null) {
  const today = new Date();
  if (dateStr !== null) {
    dateStr += ' 12:00:00';
    const date = new Date(dateStr);

    if (date <= today) {
      return false;
    } else {
      return true;
    }
  } else return false;
}

export function formatDateWithoutTime(date: Date | null) {
  if (date === null) {
    return null;
  }

  return moment(date).format('yyyy-MM-DD');
}
