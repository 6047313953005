import { createReducer, on } from '@ngrx/store';
import { PatientProfile } from '../../models/patient-profile';
import { UsState } from '../../models/state';

import * as patientInformationActions from '../actions/patient-information.actions';

export const patientInformationFeatureKey = 'patientInformation';

export interface PatientInformationState {
  isLoading: boolean;
  profile: PatientProfile | null;
  error: any;
  isSaving: boolean;
  usStates: UsState[] | null;
}

export const initialState: PatientInformationState = {
  isLoading: false,
  profile: null,
  error: null,
  isSaving: false,
  usStates: null,
};

export const patientInformationReducer = createReducer<PatientInformationState>(
  initialState,

  on(patientInformationActions.getPatientInformation, (state): PatientInformationState => {
    return {
      ...state,
      isLoading: true,
      profile: null,
    };
  }),

  on(patientInformationActions.getPatientInformationSuccess, (state, { profile }): PatientInformationState => {
    return {
      ...state,
      profile,
      isLoading: false,
      error: null,
    };
  }),

  on(patientInformationActions.getPatientInformationFailure, (state, { error }): PatientInformationState => {
    return {
      ...state,
      error,
      isLoading: false,
      isSaving: false,
      profile: null,
    };
  }),

  on(patientInformationActions.savePatientInformation, (state): PatientInformationState => {
    return {
      ...state,
      error: null,
      isSaving: true,
    };
  }),

  on(patientInformationActions.savePatientInformationSuccess, (state, { profile }): PatientInformationState => {
    return {
      ...state,
      error: null,
      isSaving: false,
      profile,
    };
  }),

  on(patientInformationActions.savePatientInformationFailure, (state, { error }): PatientInformationState => {
    return {
      ...state,
      error,
      isSaving: false,
    };
  }),

  on(patientInformationActions.getUsStatesFailure, (state, { error }): PatientInformationState => {
    return {
      ...state,
      isLoading: false,
      error,
      usStates: null,
    };
  }),

  on(patientInformationActions.getUsStates, (state): PatientInformationState => {
    return {
      ...state,
      isLoading: true,
      usStates: null,
    };
  }),

  on(patientInformationActions.getUsStatesSuccess, (state, { usStates }): PatientInformationState => {
    return {
      ...state,
      isLoading: false,
      usStates,
    };
  })
);
