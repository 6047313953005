import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthCookie } from '../../models/auth-cookie';

@Injectable({
  providedIn: 'root',
})
export class AuthCookieService {
  private readonly AUTH_COOKIE_TITLE = 'PatientIntakeAuthentication';

  constructor(private cookieService: CookieService) {}

  get accessToken(): string {
    return this.parsedAuthCookie?.accessToken || '';
  }

  get userId(): number | null {
    return this.parsedAuthCookie?.userId || null;
  }

  private get parsedAuthCookie(): AuthCookie {
    const cookie = this.cookieService.get(this.AUTH_COOKIE_TITLE);
    return cookie ? JSON.parse(cookie) : {};
  }
}
