/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';
import { PatientContactsState, patientContactsFeatureKey } from '../reducer/patient-contacts.reducer';

export const patientContactsFeatureSelector = createFeatureSelector<PatientContactsState>(patientContactsFeatureKey);

export const selectIsLoading = createSelector(patientContactsFeatureSelector, state => state.isLoading);

export const selectIsSaving = createSelector(patientContactsFeatureSelector, state => state.isSaving);

export const selectError = createSelector(patientContactsFeatureSelector, state =>
  state.error === null ? null : environment.errorMessage
);

export const selectContacts = createSelector(patientContactsFeatureSelector, state => state.contacts);

export const selectPatientContactRelationshipTypes = createSelector(
  patientContactsFeatureSelector,
  state => state.patientContactRelationshipTypes
);
