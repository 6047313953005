import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { mockAdministrativeScans } from '../../../../../../unit-test/data/administrative-scan-test-data';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { AdministrativeScan } from '../../models/administrative-scan';

@Injectable({
  providedIn: 'root',
})
export class AdministrativeScanService {
  constructor(private utilityService: UtilityService) {}

  administrativeScansEndpoint = environment.scheduleApiUrl + '/api/v1/AdministrativeScans';

  getAdministrativeScans(): Observable<AdministrativeScan[]> {
    // const url = this.administrativeScansEndpoint + '?patientId=28';
    // return this.utilityService.getRequest<AdministrativeScan>(url);
    return of(mockAdministrativeScans);
  }
}
