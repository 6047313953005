import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { PatientDemographics } from '../../models/patient-demographics';
import { getPatientDemographics } from '../../store/actions/patient-demographics.actions';
import { selectDemographics } from '../../store/selectors/patient-demographics.selectors';
import { PatientDemographicsUiComponent } from '../../ui/patient-demographics-ui/patient-demographics-ui.component';

@Component({
  selector: 'app-patient-demographics',
  standalone: true,
  imports: [CommonModule, LetDirective, PatientDemographicsUiComponent, ProgressSpinnerComponent],
  templateUrl: './patient-demographics.component.html',
  styleUrl: './patient-demographics.component.scss',
})
export class PatientDemographicsComponent implements OnInit {
  demographics$: Observable<PatientDemographics | null>;

  constructor(private store: Store) {
    this.demographics$ = this.store.select(selectDemographics);
  }

  ngOnInit(): void {
    this.store.dispatch(getPatientDemographics());
  }

  editDemographics(): void {
    console.log('Edit demographics');
  }
}
