import { createAction, props } from '@ngrx/store';
import { PatientDemographics } from '../../models/patient-demographics';
import { EthnicityType } from '../../models/types/ethnicity-types';
import { MaritalStatusType } from '../../models/types/marital-status-type';
import { PrimaryLanguageType } from '../../models/types/primary-language-type';
import { RaceType } from '../../models/types/race-type';
import { ReligionType } from '../../models/types/religion-type';

export const getPatientDemographics = createAction('[Patient] Get Patient Demographics');

export const getPatientDemographicsSuccess = createAction(
  '[Patient] Get Patient Demographics Success',
  props<{ demographics: PatientDemographics }>()
);

export const getPatientDemographicsFailure = createAction(
  '[Patient] Get Patient Demographics Failure',
  props<{ error: any }>()
);

export const getMaritalStatusTypes = createAction('[Patient] Get Marital Status Types');

export const getMaritalStatusTypesSuccess = createAction(
  '[Patient] Get Marital Status Types Success',
  props<{ maritalStatusTypes: MaritalStatusType[] }>()
);

export const getMaritalStatusTypesFailure = createAction(
  '[Patient] Get Marital Status Types Failure',
  props<{ error: any }>()
);

export const getPrimaryLanguageTypes = createAction('[Patient] Get Primary Language Types');

export const getPrimaryLanguageTypesSuccess = createAction(
  '[Patient] Get Primary Language Types Success',
  props<{ primaryLanguageTypes: PrimaryLanguageType[] }>()
);

export const getPrimaryLanguageTypesFailure = createAction(
  '[Patient] Get Primary Language Types Failure',
  props<{ error: any }>()
);

export const getRaceTypes = createAction('[Patient] Get Race Types');

export const getRaceTypesSuccess = createAction('[Patient] Get Race Types Success', props<{ raceTypes: RaceType[] }>());

export const getRaceTypesFailure = createAction('[Patient] Get Race Types Failure', props<{ error: any }>());

export const getReligionTypes = createAction('[Patient] Get Religion Types');

export const getReligionTypesSuccess = createAction(
  '[Patient] Get Religion Types Success',
  props<{ religionTypes: ReligionType[] }>()
);

export const getReligionTypesFailure = createAction('[Patient] Get Religion Types Failure', props<{ error: any }>());

export const getEthnicityTypes = createAction('[Patient] Get Ethnicity Types');

export const getEthnicityTypesSuccess = createAction(
  '[Patient] Get Ethnicity Types Success',
  props<{ ethnicityTypes: EthnicityType[] }>()
);

export const getEthnicityTypesFailure = createAction('[Patient] Get Ethnicity Types Failure', props<{ error: any }>());

export const savePatientDemographics = createAction(
  '[Patient] Save Patient Demographics',
  props<{ demographics: PatientDemographics }>()
);

export const savePatientDemographicsSuccess = createAction(
  '[Patient] Save Patient Demographics Success',
  props<{ demographics: PatientDemographics }>()
);

export const savePatientDemographicsFailure = createAction(
  '[Patient] Save Patient Demographics Failure',
  props<{ error: any }>()
);

export const openEditPatientDemographicsDialog = createAction('[Patient] Open Edit Patient Demographics Dialog');

export const editPatientDemographicsComponentLoaded = createAction(
  '[Patient] Edit Patient Demographics Component Loaded'
);

export const closeEditDialog = createAction('[Patient] Close Edit Dialog');
