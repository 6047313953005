<ng-container *ngrxLet="{ profile: profile$ } as vm">
  <app-progress-spinner
    *ngIf="vm.profile === null; else patientInfoDiv"
    data-test="spinnerPatientInfo"
    class="loading"></app-progress-spinner>

  <ng-template #patientInfoDiv>
    <app-patient-information-ui [profile]="vm.profile" (editEvent)="editProfile()"></app-patient-information-ui>
  </ng-template>
</ng-container>
