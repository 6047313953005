import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PatientDemographics } from '../../models/patient-demographics';

@Component({
  selector: 'app-patient-demographics-ui',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './patient-demographics-ui.component.html',
  styleUrl: './patient-demographics-ui.component.scss',
})
export class PatientDemographicsUiComponent {
  @Input() demographics: PatientDemographics | null = null;
  @Output() editEvent = new EventEmitter();
}
