/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { DataResponse } from '../../models/data-response';
import { AuthCookieService } from '../auth-cookie/auth-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  standardHeader = new HttpHeaders({ 'X-CSRF': '1' });

  constructor(
    private http: HttpClient,
    private authCookieService: AuthCookieService
  ) {}

  private get headers(): HttpHeaders {
    return this.standardHeader.append('Authorization', 'Bearer ' + this.authCookieService.accessToken);
  }

  getRequest<T>(url: string, rawResponse = false) {
    return rawResponse
      ? this.http.get<T>(url, { headers: this.headers })
      : this.http.get<DataResponse<T>>(url, { headers: this.headers }).pipe(map(response => response.result));
  }

  postRequest<T>(url: string, body: any, encode = false, withCredentials = false, rawResponse = false) {
    let header = this.headers;
    if (encode) header = header.append('Content-Type', 'application/x-www-form-urlencoded');

    return rawResponse
      ? this.http.post<T>(url, body, { headers: header, withCredentials })
      : this.http
          .post<DataResponse<T>>(url, body, { headers: header, withCredentials })
          .pipe(map(response => response.result));
  }

  deleteRequest<T>(url: string) {
    return this.http.delete<DataResponse<T>>(url, { headers: this.headers }).pipe(map(response => response.result));
  }

  putRequest<T>(url: string, body: any, encode = false) {
    let headers = this.headers;
    if (encode) headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.put<DataResponse<T>>(url, body, { headers: headers }).pipe(map(response => response.result));
  }
}
