import { createReducer, on } from '@ngrx/store';
import { PatientDemographics } from '../../models/patient-demographics';
import { EthnicityType } from '../../models/types/ethnicity-types';
import { MaritalStatusType } from '../../models/types/marital-status-type';
import { PrimaryLanguageType } from '../../models/types/primary-language-type';
import { RaceType } from '../../models/types/race-type';
import { ReligionType } from '../../models/types/religion-type';
import * as patientDemographicsActions from '../actions/patient-demographics.actions';

export const patientDemographicsFeatureKey = 'patientDemographics';

export interface PatientDemographicsState {
  isLoading: boolean;
  error: any;
  isSaving: boolean;
  demographics: PatientDemographics | null;
  maritalStatusTypes: MaritalStatusType[] | null;
  primaryLanguageTypes: PrimaryLanguageType[] | null;
  raceTypes: RaceType[] | null;
  religionTypes: ReligionType[] | null;
  ethnicityTypes: EthnicityType[] | null;
}

export const initialState: PatientDemographicsState = {
  isLoading: false,
  error: null,
  isSaving: false,
  demographics: null,
  maritalStatusTypes: null,
  primaryLanguageTypes: null,
  raceTypes: null,
  religionTypes: null,
  ethnicityTypes: null,
};

export const patientDemographicsReducer = createReducer<PatientDemographicsState>(
  initialState,

  on(patientDemographicsActions.getPatientDemographics, (state): PatientDemographicsState => {
    return {
      ...state,
      isLoading: true,
      demographics: null,
    };
  }),

  on(patientDemographicsActions.getPatientDemographicsSuccess, (state, { demographics }): PatientDemographicsState => {
    return {
      ...state,
      demographics,
      isLoading: false,
      error: null,
    };
  }),

  on(patientDemographicsActions.getPatientDemographicsFailure, (state, { error }): PatientDemographicsState => {
    return {
      ...state,
      error,
      isLoading: false,
      isSaving: false,
      demographics: null,
    };
  }),

  on(patientDemographicsActions.getMaritalStatusTypes, (state): PatientDemographicsState => {
    return {
      ...state,
      isLoading: true,
      maritalStatusTypes: null,
    };
  }),

  on(
    patientDemographicsActions.getMaritalStatusTypesSuccess,
    (state, { maritalStatusTypes }): PatientDemographicsState => {
      return {
        ...state,
        isLoading: false,
        maritalStatusTypes,
      };
    }
  ),

  on(
    patientDemographicsActions.getMaritalStatusTypesFailure,
    patientDemographicsActions.getPrimaryLanguageTypesFailure,
    patientDemographicsActions.getRaceTypesFailure,
    patientDemographicsActions.getReligionTypesFailure,
    patientDemographicsActions.getEthnicityTypesFailure,
    (state, { error }): PatientDemographicsState => {
      return {
        ...state,
        isLoading: false,
        error,
        maritalStatusTypes: null,
        primaryLanguageTypes: null,
        raceTypes: null,
        religionTypes: null,
        ethnicityTypes: null,
      };
    }
  ),

  on(patientDemographicsActions.getPrimaryLanguageTypes, (state): PatientDemographicsState => {
    return {
      ...state,
      isLoading: true,
      primaryLanguageTypes: null,
    };
  }),

  on(
    patientDemographicsActions.getPrimaryLanguageTypesSuccess,
    (state, { primaryLanguageTypes }): PatientDemographicsState => {
      return {
        ...state,
        isLoading: false,
        primaryLanguageTypes,
      };
    }
  ),

  on(patientDemographicsActions.getRaceTypes, (state): PatientDemographicsState => {
    return {
      ...state,
      isLoading: true,
      raceTypes: null,
    };
  }),

  on(patientDemographicsActions.getRaceTypesSuccess, (state, { raceTypes }): PatientDemographicsState => {
    return {
      ...state,
      isLoading: false,
      raceTypes,
    };
  }),

  on(patientDemographicsActions.getReligionTypes, (state): PatientDemographicsState => {
    return {
      ...state,
      isLoading: true,
      religionTypes: null,
    };
  }),

  on(patientDemographicsActions.getReligionTypesSuccess, (state, { religionTypes }): PatientDemographicsState => {
    return {
      ...state,
      isLoading: false,
      religionTypes,
    };
  }),

  on(patientDemographicsActions.getEthnicityTypes, (state): PatientDemographicsState => {
    return {
      ...state,
      isLoading: true,
      ethnicityTypes: null,
    };
  }),

  on(patientDemographicsActions.getEthnicityTypesSuccess, (state, { ethnicityTypes }): PatientDemographicsState => {
    return {
      ...state,
      isLoading: false,
      ethnicityTypes,
    };
  }),

  on(patientDemographicsActions.savePatientDemographics, (state): PatientDemographicsState => {
    return {
      ...state,
      error: null,
      isSaving: true,
    };
  }),

  on(patientDemographicsActions.savePatientDemographicsSuccess, (state, { demographics }): PatientDemographicsState => {
    return {
      ...state,
      error: null,
      isSaving: false,
      demographics,
    };
  }),

  on(patientDemographicsActions.savePatientDemographicsFailure, (state, { error }): PatientDemographicsState => {
    return {
      ...state,
      error,
      isSaving: false,
    };
  })
);
