import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PatientEmployment } from '../../models/patient-employment';

@Component({
  selector: 'app-patient-employment-ui',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, NgxMaskPipe],
  providers: [provideNgxMask()],
  templateUrl: './patient-employment-ui.component.html',
  styleUrl: './patient-employment-ui.component.scss',
})
export class PatientEmploymentUiComponent {
  @Input() employment: PatientEmployment | null = null;
  @Output() editEvent = new EventEmitter();
}
