/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';
import { patientDemographicsFeatureKey, PatientDemographicsState } from '../reducer/patient-demographics.reducer';

export const patientDemographicsFeatureSelector =
  createFeatureSelector<PatientDemographicsState>(patientDemographicsFeatureKey);

export const selectIsLoading = createSelector(patientDemographicsFeatureSelector, state => state.isLoading);

export const selectError = createSelector(patientDemographicsFeatureSelector, state =>
  state.error === null ? null : environment.errorMessage
);

export const selectIsSaving = createSelector(patientDemographicsFeatureSelector, state => state.isSaving);

export const selectDemographics = createSelector(patientDemographicsFeatureSelector, state => state.demographics);

export const selectMaritalStatusTypes = createSelector(
  patientDemographicsFeatureSelector,
  state => state.maritalStatusTypes
);

export const selectPrimaryLanguageTypes = createSelector(
  patientDemographicsFeatureSelector,
  state => state.primaryLanguageTypes
);

export const selectRaceTypes = createSelector(patientDemographicsFeatureSelector, state => state.raceTypes);

export const selectReligionTypes = createSelector(patientDemographicsFeatureSelector, state => state.religionTypes);

export const selectEthnicityTypes = createSelector(patientDemographicsFeatureSelector, state => state.ethnicityTypes);
