import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { IPatientContacts } from '../../models/patient-contacts';
import { getPatientContacts, openEditPatientContactsDialog } from '../../store/actions/patient-contacts.actions';
import { selectContacts } from '../../store/selectors/patient-contacts.selectors';
import { PatientContactsUiComponent } from '../../ui/patient-contacts-ui/patient-contacts-ui.component';

@Component({
  selector: 'app-patient-contacts',
  standalone: true,
  imports: [CommonModule, LetDirective, PatientContactsUiComponent, ProgressSpinnerComponent],
  templateUrl: './patient-contacts.component.html',
  styleUrl: './patient-contacts.component.scss',
})
export class PatientContactsComponent implements OnInit {
  contacts$: Observable<IPatientContacts | null>;

  constructor(private store: Store) {
    this.contacts$ = this.store.select(selectContacts);
  }

  ngOnInit(): void {
    this.store.dispatch(getPatientContacts());
  }

  editContacts(): void {
    this.store.dispatch(openEditPatientContactsDialog());
  }
}
