import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { PatientProfile } from '../../models/patient-profile';

@Injectable({
  providedIn: 'root',
})
export class PatientInformationService {
  constructor(private utilityService: UtilityService) {}

  patientProfileEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/PatientProfiles';

  getPatientProfile(): Observable<PatientProfile> {
    const url = this.patientProfileEndpoint + '?patientId=28';
    return this.utilityService.getRequest<PatientProfile>(url);
  }

  postPatientProfile(profile: PatientProfile): Observable<PatientProfile> {
    const body = {
      patientId: 28,
      firstName: profile.firstName,
      middleInitial: profile.middleInitial,
      lastName: profile.lastName,
      socialSecurityNumber: profile.socialSecurityNumber,
      dateOfBirth: profile.dateOfBirth,
      medicalRecordNumber: profile.medicalRecordNumber,
      primaryCareProviderFullName: profile.primaryCareProviderFullName,
      preferredFirstName: profile.preferredFirstName,
      preferredGenderPronouns: profile.preferredGenderPronouns,
      homePhoneNumber: profile.homePhoneNumber,
      mobilePhoneNumber: profile.mobilePhoneNumber,
      emailAddress: profile.emailAddress,
      residentialAddress: profile.residentialAddress,
      mailingAddress: profile.mailingAddress,
      version: profile.version,
      modifiedByUserId: 1,
    };

    return this.utilityService.postRequest<PatientProfile>(this.patientProfileEndpoint, body);
  }
}
