import { IPatientContact } from './patient-contact';

export interface IPatientContacts {
  patientId: number;
  primaryContact: IPatientContact | null;
  secondaryContact: IPatientContact | null;
  version: string;
}

export class PatientContacts {
  patientId: number;
  primaryContact: IPatientContact | null;
  secondaryContact: IPatientContact | null;
  version: string;

  constructor() {
    this.patientId = 0;
    this.primaryContact = null;
    this.secondaryContact = null;
    this.version = '';
  }
}
