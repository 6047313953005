import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { UtilityService } from '../../../../../../core/services/utility/utility.service';
import { EthnicityType } from '../../../models/types/ethnicity-types';

@Injectable({
  providedIn: 'root',
})
export class EthnicityTypesService {
  constructor(private utilityService: UtilityService) {}

  ethnicityTypesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/EthnicityTypes';

  getEthnicityTypes(): Observable<EthnicityType[]> {
    return this.utilityService.getRequest<EthnicityType[]>(this.ethnicityTypesEndpoint);
  }
}
