<div class="admin-scans-header-container">
  <div class="admin-scans-header">
    <h4>Administrative Scans</h4>
    @if (administrativeScans.length !== 0) {
      <button id="btnView" data-test="btnViewAdminScans" mat-stroked-button>View</button>
    }
  </div>
</div>

@if (administrativeScans.length > 0) {
  <div class="ml-10 mr-10 mt-20">
    @for (administrativeScan of administrativeScans; track administrativeScan) {
      <div id="adminScanContainer" class="flex flex-col justify-start mb-15 mr-20">
        <div id="descriptionAndDate" class="flex justify-between align-center">
          <div id="descriptionContainer" class="flex flex-2">
            <p class="fw-600" id="adminScanDescription">{{ administrativeScan.description }}</p>
          </div>
          <div id="dateContainer" class="flex flex-1">
            <p id="adminScanDate" class="admin-scan-date fw-600">
              {{ administrativeScan.date | date: 'M/d/YY' }}
            </p>
          </div>
          <div id="iconContainer" class="flex">
            <mat-icon
              [fontIcon]="administrativeScan.iconName"
              class="color-neutral material-symbols-outlined fs-24"></mat-icon>
          </div>
        </div>
        <div id="providerContainer" class="flex gap-4">
          <p id="adminScanProvider" class="fw-400 fs-14">
            {{ administrativeScan.provider }}
          </p>
        </div>
      </div>
    }
  </div>
} @else {
  <div class="flex justify-center mt-90">
    <p id="noAdminScansMessage" class="fs-18">No administrative scans to display</p>
  </div>
}
