<ng-container
  *ngrxLet="{
    contacts: contacts$,
  } as vm">
  <app-progress-spinner
    *ngIf="vm.contacts === null; else contactsDiv"
    data-test="spinnerPatientContacts"
    class="loading"></app-progress-spinner>

  <ng-template #contactsDiv>
    <app-patient-contacts-ui [contacts]="vm.contacts" (editEvent)="editContacts()"></app-patient-contacts-ui>
  </ng-template>
</ng-container>
