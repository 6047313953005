import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { PatientEmployment } from '../../models/patient-employment';

@Injectable({
  providedIn: 'root',
})
export class PatientEmploymentService {
  constructor(private utilityService: UtilityService) {}

  patientEmploymentEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/PatientEmployments';

  getPatientEmployment(): Observable<PatientEmployment> {
    const url = this.patientEmploymentEndpoint + '?patientId=1';
    return this.utilityService.getRequest<PatientEmployment>(url);
  }

  postPatientEmployment(employment: PatientEmployment): Observable<PatientEmployment> {
    const url = this.patientEmploymentEndpoint + '?patientId=1';
    const body = {
      employmentStatusTypeId: employment.employmentStatusTypeId,
      employerName: employment.employerName,
      employerPhoneNumber: employment.employerPhoneNumber,
      occupation: employment.occupation,
      workPhoneNumber: employment.workPhoneNumber,
      version: employment.version,
    };

    return this.utilityService.postRequest<PatientEmployment>(url, body);
  }
}
