/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';
import { administrativeScanFeatureKey, AdministrativeScanState } from '../reducer/administrative-scan.reducer';

export const administrativeScanFeatureSelector =
  createFeatureSelector<AdministrativeScanState>(administrativeScanFeatureKey);

export const selectIsLoading = createSelector(administrativeScanFeatureSelector, state => state.isLoading);

export const selectError = createSelector(administrativeScanFeatureSelector, state =>
  state.error === null ? null : environment.errorMessage
);

export const selectIsSaving = createSelector(administrativeScanFeatureSelector, state => state.isSaving);

export const selectAdministrativeScans = createSelector(
  administrativeScanFeatureSelector,
  state => state.administrativeScans
);
