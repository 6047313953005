import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getLocations, openSchedulingTips, selectLocationNameId } from '../../../../core/store/actions/app.actions';
import { selectLocations, selectSelectedLocationNameId } from '../../../../core/store/selector/app.selector';
import { LocationName } from '../../../models/location-name';
import { HeaderComponent } from '../header/header.component';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-layout-with-nav',
  standalone: true,
  imports: [CommonModule, HeaderComponent, SideNavComponent, UserMenuComponent],
  templateUrl: './layout-with-nav.component.html',
  styleUrls: ['./layout-with-nav.component.scss'],
})
export class LayoutWithNavComponent implements OnInit {
  locations$: Observable<LocationName[]>;
  selectedLocationNameId$: Observable<number>;

  constructor(private store: Store) {
    this.locations$ = this.store.select(selectLocations);
    this.selectedLocationNameId$ = this.store.select(selectSelectedLocationNameId);
  }

  ngOnInit(): void {
    this.store.dispatch(getLocations());
  }

  onSelectLocationNameId(locationNameId: number) {
    this.store.dispatch(selectLocationNameId({ locationNameId }));
  }

  openSchedulingTips(locationName: string) {
    this.store.dispatch(openSchedulingTips({ locationName }));
  }
}
