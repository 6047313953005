import { createReducer, on } from '@ngrx/store';

import { Appointment } from '../../models/appointment';
import * as appointmentActions from '../actions/appointment.actions';

export const appointmentFeatureKey = 'appointment';

export interface AppointmentState {
  isLoading: boolean;
  error: any;
  isSaving: boolean;
  appointments: Appointment[];
}

export const initialState: AppointmentState = {
  isLoading: false,
  error: null,
  isSaving: false,
  appointments: [],
};

export const appointmentReducer = createReducer<AppointmentState>(
  initialState,

  on(appointmentActions.getAppointments, (state): AppointmentState => {
    return {
      ...state,
      isLoading: true,
      appointments: [],
    };
  }),

  on(appointmentActions.getAppointmentsSuccess, (state, { appointments }): AppointmentState => {
    return {
      ...state,
      appointments,
      isLoading: false,
      error: null,
    };
  }),

  on(appointmentActions.getAppointmentsFailure, (state, { error }): AppointmentState => {
    return {
      ...state,
      error,
      isLoading: false,
      appointments: [],
    };
  })
);
