<div class="edit-contacts-header-container">
  <div class="edit-contacts-header">
    <div class="flex justify-center align-center flex-1">
      <h4>Edit Patient Contacts</h4>
    </div>
    <mat-icon
      id="btnClose"
      data-test="btnCloseEditContacts"
      fontIcon="close"
      class="material-symbols-outlined color-neutral cursor"
      (click)="cancel()"></mat-icon>
  </div>
</div>

<ng-container
  *ngrxLet="{ patientContactRelationshipTypes: patientContactRelationshipTypes$, isSaving: isSaving$ } as vm">
  <app-progress-spinner
    *ngIf="patientContacts === null || vm.patientContactRelationshipTypes === null; else editContactsForm"
    data-test="spinnerEditPatientContacts"></app-progress-spinner>

  <ng-template #editContactsForm>
    <app-progress-spinner-overlay
      [displayProgressSpinner]="vm.isSaving"
      data-test="spinnerSavePatientContacts"></app-progress-spinner-overlay>

    <div id="editContactsForm" class="edit-contacts-container">
      <form [formGroup]="contactsForm" (submit)="save()">
        <div class="form-button-container">
          <div class="flex flex-col gap-20">
            <div formGroupName="primaryContact" class="form-container">
              <div class="flex justify-between align-center">
                @if (showPrimaryForm) {
                  <h4>Primary Contact</h4>
                  <button
                    id="btnRemovePrimary"
                    type="button"
                    data-test="btnRemovePrimary"
                    mat-stroked-button
                    class="remove-button"
                    (click)="removePrimary()">
                    Remove
                  </button>
                }
              </div>

              @if (showPrimaryForm) {
                <div class="flex gap-20">
                  <div id="primaryFirstName" class="form-field flex-1">
                    <mat-label>First Name <span class="color-warn">*</span></mat-label>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                      <input
                        id="iptPrimaryFirstName"
                        matInput
                        type="text"
                        formControlName="firstName"
                        data-test="iptPrimaryFirstName" />
                      @if (contactsForm.controls['primaryContact'].controls['firstName'].hasError('maxlength')) {
                        <mat-error id="errPrimaryFirstNameMax" data-test="matErrPrimaryFirstNameMax"
                          >Must be 100 characters or less</mat-error
                        >
                      }
                      @if (contactsForm.controls['primaryContact'].controls['firstName'].hasError('required')) {
                        <mat-error id="errPrimaryFirstNameRequired" data-test="matErrPrimaryFirstNameRequired"
                          >Required</mat-error
                        >
                      }
                    </mat-form-field>
                  </div>

                  <div id="primaryLastName" class="form-field flex-1">
                    <mat-label>Last Name <span class="color-warn">*</span></mat-label>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                      <input matInput type="text" formControlName="lastName" data-test="iptPrimaryLastName" />
                      @if (contactsForm.controls['primaryContact'].controls['lastName'].hasError('maxlength')) {
                        <mat-error id="errPrimaryLastNameMax" data-test="matErrPrimaryLastNameMax"
                          >Must be 100 characters or less</mat-error
                        >
                      }
                      @if (contactsForm.controls['primaryContact'].controls['lastName'].hasError('required')) {
                        <mat-error id="errPrimaryLastNameRequired" data-test="matErrPrimaryLastNameRequired"
                          >Required</mat-error
                        >
                      }
                    </mat-form-field>
                  </div>
                </div>

                <div class="flex gap-20">
                  <div class="form-field flex-1">
                    <mat-label>Mobile Phone</mat-label>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                      <input
                        id="primaryMobilePhone"
                        type="tel"
                        matInput
                        formControlName="mobilePhone"
                        data-test="iptMobilePhone"
                        mask="(000) 000-0000" />
                      @if (contactsForm.controls['primaryContact'].controls['mobilePhone'].hasError('mask')) {
                        <mat-error id="errPrimaryMobilePhoneLength" data-test="matErrPrimaryMobilePhoneLength"
                          >Must be 10 digits</mat-error
                        >
                      }
                    </mat-form-field>
                  </div>

                  <div class="form-field flex-1">
                    <mat-label>Home Phone</mat-label>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                      <input
                        id="primaryHomePhone"
                        type="tel"
                        matInput
                        formControlName="homePhone"
                        data-test="iptHomePhone"
                        mask="(000) 000-0000" />
                      @if (contactsForm.controls['primaryContact'].controls['homePhone'].hasError('mask')) {
                        <mat-error id="errPrimaryHomePhoneLength" data-test="matErrPrimaryHomePhoneLength"
                          >Must be 10 digits</mat-error
                        >
                      }
                    </mat-form-field>
                  </div>
                </div>

                <div class="flex gap-20">
                  <div id="primaryRelationship" class="form-field flex-1">
                    <mat-label>Relationship to Patient <span class="color-warn">*</span></mat-label>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                      <mat-select formControlName="relationship" data-test="selPrimaryRelationshipTypes">
                        @for (relationship of patientContactRelationshipTypes$ | async; track relationship) {
                          <mat-option [value]="relationship.patientContactRelationshipTypeId">{{
                            relationship.description
                          }}</mat-option>
                        }
                      </mat-select>
                      @if (contactsForm.controls['primaryContact'].controls['relationship'].hasError('required')) {
                        <mat-error id="errPrimaryRelationshipRequired" data-test="matErrPrimaryRelationshipRequired"
                          >Required</mat-error
                        >
                      }
                    </mat-form-field>
                  </div>

                  <div class="flex-1"></div>
                </div>
              } @else {
                <button
                  id="btnAddPrimary"
                  data-test="btnAddPrimary"
                  mat-stroked-button
                  class="add-button margin-auto"
                  (click)="showPrimaryForm = true; this.contactsForm.controls['primaryContact'].enable()">
                  Add Emergency Contact
                </button>
              }
            </div>

            @if (showPrimaryForm) {
              <div id="divider" class="divider"></div>
            }

            @if (showPrimaryForm) {
              <div id="secondaryForm" formGroupName="secondaryContact" class="form-container">
                <div class="flex justify-between align-center">
                  <h4>Secondary Contact</h4>
                  @if (showSecondaryForm) {
                    <button
                      id="btnRemoveSecondary"
                      type="button"
                      data-test="btnRemoveSecondary"
                      mat-stroked-button
                      class="remove-button"
                      (click)="removeSecondary()">
                      Remove
                    </button>
                  }
                </div>

                @if (showSecondaryForm) {
                  <div class="flex gap-20">
                    <div class="form-field flex-1">
                      <mat-label>First Name <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          id="secondaryFirstName"
                          matInput
                          type="text"
                          formControlName="firstName"
                          data-test="iptSecondaryFirstName" />
                        @if (contactsForm.controls['secondaryContact'].controls['firstName'].hasError('maxlength')) {
                          <mat-error id="errSecondaryFirstNameMax" data-test="matErrSecondaryFirstNameMax"
                            >Must be 100 characters or less</mat-error
                          >
                        }
                        @if (contactsForm.controls['secondaryContact'].controls['firstName'].hasError('required')) {
                          <mat-error id="errSecondaryFirstNameRequired" data-test="matErrSecondaryFirstNameRequired"
                            >Required</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="form-field flex-1">
                      <mat-label>Last Name <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          id="secondaryLastName"
                          matInput
                          type="text"
                          formControlName="lastName"
                          data-test="iptSecondaryLastName" />
                        @if (contactsForm.controls['secondaryContact'].controls['lastName'].hasError('maxlength')) {
                          <mat-error id="errSecondaryLastNameMax" data-test="matErrSecondaryLastNameMax"
                            >Must be 100 characters or less</mat-error
                          >
                        }
                        @if (contactsForm.controls['secondaryContact'].controls['lastName'].hasError('required')) {
                          <mat-error id="errSecondaryLastNameRequired" data-test="matErrSecondaryLastNameRequired"
                            >Required</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex gap-20">
                    <div class="form-field flex-1">
                      <mat-label>Mobile Phone</mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          id="secondaryMobilePhone"
                          type="tel"
                          matInput
                          formControlName="mobilePhone"
                          data-test="iptSecondaryMobilePhone"
                          mask="(000) 000-0000" />
                        @if (contactsForm.controls['secondaryContact'].controls['mobilePhone'].hasError('mask')) {
                          <mat-error id="errSecondaryMobilePhoneLength" data-test="matErrSecondaryMobilePhoneLength"
                            >Must be 10 digits</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="form-field flex-1">
                      <mat-label>Home Phone</mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          id="secondaryHomePhone"
                          type="tel"
                          matInput
                          formControlName="homePhone"
                          data-test="iptSecondaryHomePhone"
                          mask="(000) 000-0000" />
                        @if (contactsForm.controls['secondaryContact'].controls['homePhone'].hasError('mask')) {
                          <mat-error id="errSecondaryHomePhoneLength" data-test="matErrSecondaryHomePhoneLength"
                            >Must be 10 digits</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex gap-20">
                    <div id="secondaryRelationship" class="form-field flex-1">
                      <mat-label>Relationship to Patient <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-select formControlName="relationship" data-test="selSecondaryRelationshipTypes">
                          @for (relationship of patientContactRelationshipTypes$ | async; track relationship) {
                            <mat-option [value]="relationship.patientContactRelationshipTypeId">{{
                              relationship.description
                            }}</mat-option>
                          }
                        </mat-select>
                        @if (contactsForm.controls['secondaryContact'].controls['relationship'].hasError('required')) {
                          <mat-error
                            id="errSecondaryRelationshipRequired"
                            data-test="matErrSecondaryRelationshipRequired"
                            >Required</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="flex-1"></div>
                  </div>
                } @else {
                  <button
                    id="btnAddSecondary"
                    data-test="btnAddSecondary"
                    mat-stroked-button
                    class="add-button margin-auto"
                    (click)="showSecondaryForm = true; this.contactsForm.controls['secondaryContact'].enable()">
                    Add Emergency Contact
                  </button>
                }
              </div>
            }
          </div>

          <div class="button-container">
            <button
              id="btnCancel"
              data-test="btnCancelEditContacts"
              mat-stroked-button
              type="button"
              class="cancel-button"
              (click)="cancel()">
              Cancel
            </button>
            <button
              id="btnSave"
              data-test="btnSaveEditContacts"
              mat-raised-button
              type="submit"
              class="save-button"
              color="primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</ng-container>
