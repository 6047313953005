import { AdministrativeScan } from '../../app/patient/patient-profile/administrative-scan/models/administrative-scan';

export const mockAdministrativeScans: AdministrativeScan[] = [
  {
    description: 'Insurance Card',
    date: '2024-12-1',
    iconName: 'medical_information',
    provider: 'Dr. Patel',
  },
  {
    description: 'Insurance Card',
    date: '2024-12-3',
    iconName: 'medical_information',
    provider: 'Dr. Richards',
  },
  {
    description: 'Driver License',
    date: '2024-12-3',
    iconName: 'identity_platform',
    provider: 'Dr. Jamison',
  },
  {
    description: 'Intake Form',
    date: '2024-12-3',
    iconName: 'description',
    provider: 'Dr. Richards',
  },
  {
    description: 'Passport Card',
    date: '2024-12-9',
    iconName: 'identity_platform',
    provider: 'Dr. Doolittle',
  },
  {
    description: 'Insurance Card',
    date: '2024-12-11',
    iconName: 'medical_information',
    provider: 'Dr. Patel',
  },
  {
    description: 'Insurance Card',
    date: '2024-12-13',
    iconName: 'medical_information',
    provider: 'Dr. Richards',
  },
  {
    description: 'Driver License',
    date: '2024-12-13',
    iconName: 'identity_platform',
    provider: 'Dr. Jamison',
  },
  {
    description: 'Intake Form',
    date: '2024-12-17',
    iconName: 'description',
    provider: 'Dr. Richards',
  },
  {
    description: 'Passport Card',
    date: '2024-12-17',
    iconName: 'identity_platform',
    provider: 'Dr. Doolittle',
  },
];
