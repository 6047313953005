import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { PatientContactsComponent } from '../../patient-contacts/feature/patient-contacts/patient-contacts.component';
import { PatientDemographicsComponent } from '../../patient-demographics/feature/patient-demographics/patient-demographics.component';
import { PatientEmploymentComponent } from '../../patient-employment/feature/patient-employment/patient-employment.component';
import { PatientInformationComponent } from '../../patient-information/feature/patient-information/patient-information.component';
@Component({
  selector: 'app-patient-profile',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    PatientDemographicsComponent,
    PatientContactsComponent,
    PatientEmploymentComponent,
    PatientInformationComponent,
  ],
  templateUrl: './patient-profile.component.html',
  styleUrl: './patient-profile.component.scss',
})
export class PatientProfileComponent {}
