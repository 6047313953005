/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';
import { patientEmploymentFeatureKey, PatientEmploymentState } from '../reducer/patient-employment.reducer';

export const patientEmploymentFeatureSelector =
  createFeatureSelector<PatientEmploymentState>(patientEmploymentFeatureKey);

export const selectIsLoading = createSelector(patientEmploymentFeatureSelector, state => state.isLoading);

export const selectError = createSelector(patientEmploymentFeatureSelector, state =>
  state.error === null ? null : environment.errorMessage
);

export const selectIsSaving = createSelector(patientEmploymentFeatureSelector, state => state.isSaving);

export const selectEmployment = createSelector(patientEmploymentFeatureSelector, state => state.employment);

export const selectEmploymentStatuses = createSelector(
  patientEmploymentFeatureSelector,
  state => state.employmentStatusTypes
);
