<div class="header-container">
  <div id="imgDiv" class="logo cursor" (click)="goHome()" (keyup)="goHome()" tabindex="0" data-test="btnHome">
    <img id="imgLogo" src="logo-small.svg" class="mr-5 color-white" alt="Logo image" />
  </div>
  <div class="flex flex-2 gap-20">
    <mat-form-field class="location-form-field" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
      <mat-label>Location</mat-label>
      <mat-select
        class="location-select"
        [value]="selectedLocationNameId"
        (selectionChange)="selectLocationNameIdEvent.emit($event.value)">
        @for (location of locations; track location) {
          <mat-option [value]="location.locationId">{{ location.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button
      class="tips-button btn"
      id="btnSchedulingTips"
      (click)="openSchedulingTips()"
      mat-stroked-button
      [disabled]="isSaving"
      type="button">
      Scheduling Tips
    </button>
  </div>
</div>
