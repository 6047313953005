import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SchedulingTips } from '../../../../core/models/scheduling-tips';
import { getSchedulingTips } from '../../../../core/store/actions/app.actions';
import * as selectors from '../../../../core/store/selector/app.selector';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-scheduling-tips-dialog',
  standalone: true,
  imports: [LetDirective, ProgressSpinnerComponent],
  templateUrl: './scheduling-tips-dialog.component.html',
  styleUrl: './scheduling-tips-dialog.component.scss',
})
export class SchedulingTipsDialogComponent implements OnInit {
  schedulingTips$: Observable<SchedulingTips | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<Error | null>;
  locationName: string;

  constructor(
    public dialogRef: MatDialogRef<SchedulingTipsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      locationName: string;
    },
    public store: Store
  ) {
    this.schedulingTips$ = this.store.select(selectors.selectSchedulingTips);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.error$ = this.store.select(selectors.selectError);
    this.locationName = data.locationName;
  }

  ngOnInit(): void {
    this.store.dispatch(getSchedulingTips());
  }

  close() {
    this.dialogRef.close();
  }
}
