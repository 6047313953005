import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SchedulingTips } from '../../models/scheduling-tips';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulingTipsService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/api/v1/LocationNotes';

  getSchedulingTips(locationId: number): Observable<SchedulingTips> {
    return this.utilityService.getRequest<SchedulingTips>(this.endpoint + '?locationId=' + locationId);
  }
}
