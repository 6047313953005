import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Observable } from 'rxjs';
import { ProgressSpinnerOverlayComponent } from '../../../../../shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { PatientContact } from '../../models/patient-contact';
import { PatientContacts } from '../../models/patient-contacts';
import { PatientContactRelationshipType } from '../../models/types/patient-contact-relationship-types';
import {
  closeEditDialog,
  getPatientContactRelationshipTypes,
  savePatientContacts,
} from '../../store/actions/patient-contacts.actions';
import {
  selectIsSaving,
  selectPatientContactRelationshipTypes,
} from '../../store/selectors/patient-contacts.selectors';

@Component({
  selector: 'app-edit-contacts',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LetDirective,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgxMaskDirective,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule,
  ],
  providers: [provideNgxMask()],
  templateUrl: './edit-patient-contacts.component.html',
  styleUrl: './edit-patient-contacts.component.scss',
})
export class EditPatientContactsComponent implements OnInit {
  patientContacts: PatientContacts | null;
  patientContactRelationshipTypes$: Observable<PatientContactRelationshipType[] | null>;
  isSaving$: Observable<boolean | null>;

  contactsForm = new FormGroup({
    primaryContact: new FormGroup({
      firstName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(100)]),
      lastName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(100)]),
      mobilePhone: new FormControl<string>(''),
      homePhone: new FormControl<string | null>(''),
      relationship: new FormControl<number | null>(null, Validators.required),
    }),
    secondaryContact: new FormGroup({
      firstName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(100)]),
      lastName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(100)]),
      mobilePhone: new FormControl<string>(''),
      homePhone: new FormControl<string | null>(''),
      relationship: new FormControl<number | null>(null, Validators.required),
    }),
  });
  showPrimaryForm: boolean = false;
  showSecondaryForm: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientContacts: PatientContacts | null;
    },
    private store: Store
  ) {
    this.patientContactRelationshipTypes$ = this.store.select(selectPatientContactRelationshipTypes);
    this.isSaving$ = this.store.select(selectIsSaving);
    this.patientContacts = data.patientContacts;

    if (this.patientContacts !== null) {
      this.showPrimaryForm = true;
      if (this.patientContacts.primaryContact !== undefined && this.patientContacts.primaryContact !== null) {
        this.contactsForm.controls['primaryContact'].controls['firstName'].patchValue(
          this.patientContacts.primaryContact.firstName
        );
        this.contactsForm.controls['primaryContact'].controls['lastName'].patchValue(
          this.patientContacts.primaryContact.lastName
        );
        this.contactsForm.controls['primaryContact'].controls['mobilePhone'].patchValue(
          this.patientContacts.primaryContact.mobilePhoneNumber
        );
        this.contactsForm.controls['primaryContact'].controls['homePhone'].patchValue(
          this.patientContacts.primaryContact.homePhoneNumber
        );
        this.contactsForm.controls['primaryContact'].controls['relationship'].patchValue(
          this.patientContacts.primaryContact.patientContactRelationshipTypeId
        );
      }

      if (this.patientContacts.secondaryContact !== undefined && this.patientContacts.secondaryContact !== null) {
        this.contactsForm.controls['secondaryContact'].controls['firstName'].patchValue(
          this.patientContacts.secondaryContact.firstName
        );
        this.contactsForm.controls['secondaryContact'].controls['lastName'].patchValue(
          this.patientContacts.secondaryContact.lastName
        );
        this.contactsForm.controls['secondaryContact'].controls['mobilePhone'].patchValue(
          this.patientContacts.secondaryContact.mobilePhoneNumber
        );
        this.contactsForm.controls['secondaryContact'].controls['homePhone'].patchValue(
          this.patientContacts.secondaryContact.homePhoneNumber
        );
        this.contactsForm.controls['secondaryContact'].controls['relationship'].patchValue(
          this.patientContacts.secondaryContact.patientContactRelationshipTypeId
        );
      } else {
        this.contactsForm.controls['secondaryContact'].disable();
      }

      this.showSecondaryForm =
        this.patientContacts.secondaryContact !== undefined && this.patientContacts.secondaryContact !== null;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(getPatientContactRelationshipTypes());
  }

  removePrimary() {
    if (this.contactsForm.controls['secondaryContact'].controls['firstName'].value !== null) {
      this.contactsForm.controls['primaryContact'].controls['firstName'].patchValue(
        this.contactsForm.controls['secondaryContact'].controls['firstName'].value
      );

      this.contactsForm.controls['primaryContact'].controls['lastName'].patchValue(
        this.contactsForm.controls['secondaryContact'].controls['lastName'].value
      );

      this.contactsForm.controls['primaryContact'].controls['mobilePhone'].patchValue(
        this.contactsForm.controls['secondaryContact'].controls['mobilePhone'].value
      );

      this.contactsForm.controls['primaryContact'].controls['homePhone'].patchValue(
        this.contactsForm.controls['secondaryContact'].controls['homePhone'].value
      );

      this.contactsForm.controls['primaryContact'].controls['relationship'].patchValue(
        this.contactsForm.controls['secondaryContact'].controls['relationship'].value
      );

      this.contactsForm.controls['primaryContact'].enable();

      this.removeSecondary();
    } else {
      this.contactsForm.controls['primaryContact'].controls['firstName'].reset();
      this.contactsForm.controls['primaryContact'].controls['lastName'].reset();
      this.contactsForm.controls['primaryContact'].controls['mobilePhone'].reset();
      this.contactsForm.controls['primaryContact'].controls['homePhone'].reset();
      this.contactsForm.controls['primaryContact'].controls['relationship'].reset();
      this.contactsForm.controls['primaryContact'].disable();
      this.showPrimaryForm = false;
    }
  }

  removeSecondary() {
    this.contactsForm.controls['secondaryContact'].controls['firstName'].reset();
    this.contactsForm.controls['secondaryContact'].controls['lastName'].reset();
    this.contactsForm.controls['secondaryContact'].controls['mobilePhone'].reset();
    this.contactsForm.controls['secondaryContact'].controls['homePhone'].reset();
    this.contactsForm.controls['secondaryContact'].controls['relationship'].reset();
    this.contactsForm.controls['secondaryContact'].disable();
    this.showSecondaryForm = false;
  }

  save() {
    if (this.patientContacts !== null && (this.contactsForm.valid || this.contactsForm.disabled)) {
      const newContacts = new PatientContacts();

      if (this.contactsForm.valid) {
        if (this.showPrimaryForm) {
          newContacts.primaryContact = new PatientContact();
          newContacts.primaryContact.firstName =
            this.contactsForm.controls['primaryContact'].controls['firstName'].value!;
          newContacts.primaryContact.lastName =
            this.contactsForm.controls['primaryContact'].controls['lastName'].value!;
          newContacts.primaryContact.patientContactRelationshipTypeId =
            this.contactsForm.controls['primaryContact'].controls['relationship'].value!;
          newContacts.primaryContact.mobilePhoneNumber =
            this.contactsForm.controls['primaryContact'].controls['mobilePhone'].value;
          newContacts.primaryContact.homePhoneNumber =
            this.contactsForm.controls['primaryContact'].controls['homePhone'].value;
        }

        if (this.showSecondaryForm) {
          newContacts.secondaryContact = new PatientContact();
          newContacts.secondaryContact.firstName =
            this.contactsForm.controls['secondaryContact'].controls['firstName'].value!;
          newContacts.secondaryContact.lastName =
            this.contactsForm.controls['secondaryContact'].controls['lastName'].value!;
          newContacts.secondaryContact.patientContactRelationshipTypeId =
            this.contactsForm.controls['secondaryContact'].controls['relationship'].value!;
          newContacts.secondaryContact.mobilePhoneNumber =
            this.contactsForm.controls['secondaryContact'].controls['mobilePhone'].value;
          newContacts.secondaryContact.homePhoneNumber =
            this.contactsForm.controls['secondaryContact'].controls['homePhone'].value;
        }
      } else if (this.contactsForm.disabled) {
        newContacts.primaryContact = null;
        newContacts.secondaryContact = null;
      }

      newContacts.version = this.patientContacts.version;

      this.store.dispatch(savePatientContacts({ contacts: newContacts }));
    }
  }

  cancel() {
    this.store.dispatch(closeEditDialog());
  }
}
