import { createReducer, on } from '@ngrx/store';

import { AdministrativeScan } from '../../models/administrative-scan';
import * as administrativeScanActions from '../actions/administrative-scan.actions';

export const administrativeScanFeatureKey = 'administrativeScan';

export interface AdministrativeScanState {
  isLoading: boolean;
  error: any;
  isSaving: boolean;
  administrativeScans: AdministrativeScan[];
}

export const initialState: AdministrativeScanState = {
  isLoading: false,
  error: null,
  isSaving: false,
  administrativeScans: [],
};

export const administrativeScanReducer = createReducer<AdministrativeScanState>(
  initialState,

  on(administrativeScanActions.getAdministrativeScans, (state): AdministrativeScanState => {
    return {
      ...state,
      isLoading: true,
      administrativeScans: [],
    };
  }),

  on(
    administrativeScanActions.getAdministrativeScansSuccess,
    (state, { administrativeScans }): AdministrativeScanState => {
      return {
        ...state,
        administrativeScans,
        isLoading: false,
        error: null,
      };
    }
  ),

  on(administrativeScanActions.getAdministrativeScansFailure, (state, { error }): AdministrativeScanState => {
    return {
      ...state,
      error,
      isLoading: false,
      administrativeScans: [],
    };
  })
);
