import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { LocationName } from '../../../models/location-name';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, MatButton],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() locations: LocationName[] | null = null;
  @Input() selectedLocationNameId: number | null = -1;
  @Output() selectLocationNameIdEvent = new EventEmitter<number>();
  @Output() openSchedulingTipsEvent = new EventEmitter<string>();

  isSaving = false;

  title = environment.title;

  constructor(private router: Router) {}

  goHome() {
    this.router.navigate(['']);
  }

  openSchedulingTips() {
    const selectedLocation = this.locations?.find(l => l.locationId === this.selectedLocationNameId);
    this.openSchedulingTipsEvent.emit(selectedLocation?.name);
  }
}
