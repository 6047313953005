import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { getAppointments, getAppointmentsFailure, getAppointmentsSuccess } from '../actions/appointment.actions';

@Injectable()
export class AppointmentEffects {
  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentService,
    private logger: NGXLogger
  ) {}

  getAppointments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAppointments),
      mergeMap(() => {
        return this.appointmentService.getAppointments().pipe(
          map(appointments => {
            return getAppointmentsSuccess({ appointments });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getAppointmentsFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });
}
