import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { PatientDemographicsService } from '../../services/patient-demographics/patient-demographics.service';
import { EthnicityTypesService } from '../../services/types/ethnicity-types/ethnicity-types.service';
import { MaritalStatusTypesService } from '../../services/types/marital-status-types/marital-status-types.service';
import { PrimaryLanguageTypesService } from '../../services/types/primary-language-types/primary-language-types.service';
import { RaceTypesService } from '../../services/types/race-types/race-types.service';
import { ReligionTypesService } from '../../services/types/religion-types/religion-types.service';
import { EditPatientDemographicsComponent } from '../../ui/edit-patient-demographics/edit-patient-demographics.component';
import {
  closeEditDialog,
  editPatientDemographicsComponentLoaded,
  getEthnicityTypes,
  getEthnicityTypesFailure,
  getEthnicityTypesSuccess,
  getMaritalStatusTypes,
  getMaritalStatusTypesFailure,
  getMaritalStatusTypesSuccess,
  getPatientDemographics,
  getPatientDemographicsFailure,
  getPatientDemographicsSuccess,
  getPrimaryLanguageTypes,
  getPrimaryLanguageTypesFailure,
  getPrimaryLanguageTypesSuccess,
  getRaceTypes,
  getRaceTypesFailure,
  getRaceTypesSuccess,
  getReligionTypes,
  getReligionTypesFailure,
  getReligionTypesSuccess,
  openEditPatientDemographicsDialog,
  savePatientDemographics,
  savePatientDemographicsFailure,
  savePatientDemographicsSuccess,
} from '../actions/patient-demographics.actions';
import { selectDemographics } from '../selectors/patient-demographics.selectors';

@Injectable()
export class PatientDemographicsEffects {
  constructor(
    private actions$: Actions,
    private demographicsService: PatientDemographicsService,
    private maritalStatusTypesService: MaritalStatusTypesService,
    private primaryLanguageTypesService: PrimaryLanguageTypesService,
    private raceTypesService: RaceTypesService,
    private religionTypesService: ReligionTypesService,
    private ethnicityTypesService: EthnicityTypesService,
    private store: Store,
    private logger: NGXLogger,
    private dialog: MatDialog
  ) {}

  getMaritalStatusTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMaritalStatusTypes, editPatientDemographicsComponentLoaded),
      mergeMap(() => {
        return this.maritalStatusTypesService.getMaritalStatusTypes().pipe(
          map(maritalStatusTypes => {
            return getMaritalStatusTypesSuccess({ maritalStatusTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getMaritalStatusTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getPrimaryLanguageTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPrimaryLanguageTypes, editPatientDemographicsComponentLoaded),
      mergeMap(() => {
        return this.primaryLanguageTypesService.getPrimaryLanguageTypes().pipe(
          map(primaryLanguageTypes => {
            return getPrimaryLanguageTypesSuccess({ primaryLanguageTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPrimaryLanguageTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getRaceTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getRaceTypes, editPatientDemographicsComponentLoaded),
      mergeMap(() => {
        return this.raceTypesService.getRaceTypes().pipe(
          map(raceTypes => {
            return getRaceTypesSuccess({ raceTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getRaceTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getReligionTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getReligionTypes, editPatientDemographicsComponentLoaded),
      mergeMap(() => {
        return this.religionTypesService.getReligionTypes().pipe(
          map(religionTypes => {
            return getReligionTypesSuccess({ religionTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getReligionTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getEthnicityTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getEthnicityTypes, editPatientDemographicsComponentLoaded),
      mergeMap(() => {
        return this.ethnicityTypesService.getEthnicityTypes().pipe(
          map(ethnicityTypes => {
            return getEthnicityTypesSuccess({ ethnicityTypes });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getEthnicityTypesFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  getPatientDemographics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPatientDemographics),
      mergeMap(() => {
        return this.demographicsService.getPatientDemographics().pipe(
          map(demographics => {
            return getPatientDemographicsSuccess({ demographics });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getPatientDemographicsFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  savePatientDemographics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePatientDemographics),
      mergeMap(action => {
        return this.demographicsService.postPatientDemographics(action.demographics).pipe(
          map(demographics => {
            return savePatientDemographicsSuccess({ demographics });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(savePatientDemographicsFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  openEditPatientDemographicsDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(openEditPatientDemographicsDialog),
        concatLatestFrom(() => [this.store.select(selectDemographics)]),
        tap(([, patientDemographics]) => {
          this.dialog.open(EditPatientDemographicsComponent, {
            id: 'edit-patient-demographics-dialog',
            data: {
              patientDemographics,
            },
            disableClose: true,
            autoFocus: false,
            width: '750px',
            height: '405px',
          });
        })
      );
    },
    { dispatch: false }
  );

  closeEditPatientDemographicsDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(savePatientDemographicsSuccess, closeEditDialog),
        tap(() => {
          this.dialog.closeAll();
        })
      );
    },
    { dispatch: false }
  );
}
