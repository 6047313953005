<div class="emp-header-container">
  <div class="emp-header">
    <h4>Employer</h4>
    @if (employment !== null && employment.employmentStatusTypeId !== null) {
      <button id="btnEdit" data-test="btnEditPatientEmp" mat-stroked-button (click)="editEvent.emit()">Edit</button>
    }
  </div>
</div>

<div class="emp-container">
  @if (employment !== null) {
    <div class="flex flex-col gap-20">
      @if (employment.employmentStatusTypeId !== null) {
        @if (employment.employerName !== null && employment.employerName !== '') {
          <h4 id="employerName" data-test="strEmployerName">{{ employment.employerName }}</h4>
        }

        <div class="row-container">
          <div class="flex align-start gap-8">
            <mat-icon fontIcon="call" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
            <div class="flex flex-col gap-4">
              <p class="mat-label-medium">Employer Phone</p>
              @if (employment.employerPhoneNumber !== null && employment.employerPhoneNumber !== '') {
                <p id="employerPhoneNumber" class="fw-500" data-test="strEmployerPhoneNumber">
                  {{ employment.employerPhoneNumber | mask: '(000) 000-0000' }}
                </p>
              } @else {
                <p id="employerPhoneNumberNA" class="fw-500" data-test="strEmployerPhoneNumberNA">Not Available</p>
              }
            </div>
          </div>

          <div class="flex align-start gap-8">
            <mat-icon fontIcon="call" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
            <div class="flex flex-col gap-4">
              <p class="mat-label-medium">Patient's Work Phone</p>
              @if (employment.workPhoneNumber !== null && employment.workPhoneNumber !== '') {
                <p id="workPhoneNumber" class="fw-500" data-test="strWorkPhoneNumber">
                  {{ employment.workPhoneNumber | mask: '(000) 000-0000' }}
                </p>
              } @else {
                <p id="workPhoneNumberNA" class="fw-500" data-test="strWorkPhoneNumberNA">Not Available</p>
              }
            </div>
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="ballot" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Status</p>
            <p id="employmentStatus" class="fw-500" data-test="strEmploymentStatus">
              {{ employment.employmentStatus }}
            </p>
          </div>
        </div>
      } @else {
        <div class="flex justify-center align-center mt-20">
          <button
            id="btnAdd"
            data-test="btnAddPatientEmp"
            mat-stroked-button
            class="add-button"
            (click)="editEvent.emit()">
            Add Employment
          </button>
        </div>
      }
    </div>
  }
</div>
