import { createReducer, on } from '@ngrx/store';
import { IPatientContacts } from '../../models/patient-contacts';
import { PatientContactRelationshipType } from '../../models/types/patient-contact-relationship-types';
import * as patientContactActions from '../actions/patient-contacts.actions';

export const patientContactsFeatureKey = 'patientContacts';

export interface PatientContactsState {
  isLoading: boolean;
  error: any;
  isSaving: boolean;
  contacts: IPatientContacts | null;
  patientContactRelationshipTypes: PatientContactRelationshipType[] | null;
}

export const initialState: PatientContactsState = {
  isLoading: false,
  error: null,
  isSaving: false,
  contacts: null,
  patientContactRelationshipTypes: null,
};

export const patientContactsReducer = createReducer<PatientContactsState>(
  initialState,

  on(patientContactActions.getPatientContacts, (state): PatientContactsState => {
    return {
      ...state,
      isLoading: true,
      contacts: null,
    };
  }),

  on(patientContactActions.getPatientContactsSuccess, (state, { contacts }): PatientContactsState => {
    return {
      ...state,
      contacts,
      isLoading: false,
      error: null,
    };
  }),

  on(patientContactActions.getPatientContactsFailure, (state, { error }): PatientContactsState => {
    return {
      ...state,
      error,
      isLoading: false,
      isSaving: false,
      contacts: null,
    };
  }),

  on(patientContactActions.getPatientContactRelationshipTypesFailure, (state, { error }): PatientContactsState => {
    return {
      ...state,
      isLoading: false,
      error,
      patientContactRelationshipTypes: null,
    };
  }),

  on(patientContactActions.getPatientContactRelationshipTypes, (state): PatientContactsState => {
    return {
      ...state,
      isLoading: true,
      patientContactRelationshipTypes: null,
    };
  }),

  on(
    patientContactActions.getPatientContactRelationshipTypesSuccess,
    (state, { patientContactRelationshipTypes }): PatientContactsState => {
      return {
        ...state,
        isLoading: false,
        patientContactRelationshipTypes,
      };
    }
  ),

  on(patientContactActions.savePatientContacts, (state): PatientContactsState => {
    return {
      ...state,
      error: null,
      isSaving: true,
    };
  }),

  on(patientContactActions.savePatientContactsSuccess, (state, { contacts }): PatientContactsState => {
    return {
      ...state,
      error: null,
      isSaving: false,
      contacts,
    };
  }),

  on(patientContactActions.savePatientContactsFailure, (state, { error }): PatientContactsState => {
    return {
      ...state,
      error,
      isSaving: false,
    };
  })
);
