import { NavListItem } from '../models/nav-list-item';

export const navList: NavListItem[] = [
  {
    icon: 'today',
    name: 'Main Schedule',
    route: 'main-schedule',
  },
  {
    icon: 'calendar_month',
    name: 'Calendar',
    route: 'calendar',
  },
];
