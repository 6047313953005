import { createAction, props } from '@ngrx/store';
import { PatientProfile } from '../../models/patient-profile';
import { UsState } from '../../models/state';

export const getPatientInformation = createAction('[Patient] Get Patient Information');

export const getPatientInformationSuccess = createAction(
  '[Patient] Get Patient Information Success',
  props<{ profile: PatientProfile }>()
);

export const getPatientInformationFailure = createAction(
  '[Patient] Get Patient Information Failure',
  props<{ error: any }>()
);

export const getUsStates = createAction('[Patient] Get States');

export const getUsStatesSuccess = createAction('[Patient] Get States Success', props<{ usStates: UsState[] }>());

export const getUsStatesFailure = createAction('[Patient] Get States Failure', props<{ error: any }>());

export const savePatientInformation = createAction(
  '[Patient] Save Patient Information',
  props<{ profile: PatientProfile }>()
);

export const savePatientInformationSuccess = createAction(
  '[Patient] Save Patient Information Success',
  props<{ profile: PatientProfile }>()
);

export const savePatientInformationFailure = createAction(
  '[Patient] Save Patient Information Failure',
  props<{ error: any }>()
);

export const openEditPatientInformationDialog = createAction('[Patient] Open Edit Patient Information Dialog');

export const closeEditDialog = createAction('[Patient] Close Edit Dialog');
