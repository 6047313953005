import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
  getAdministrativeScans,
  getAdministrativeScansFailure,
  getAdministrativeScansSuccess,
} from '../actions/administrative-scan.actions';
import { AdministrativeScanService } from '../../services/administrative-scan/administrative-scan.service';

@Injectable()
export class AdministrativeScanEffects {
  constructor(
    private actions$: Actions,
    private administrativeScanService: AdministrativeScanService,
    private logger: NGXLogger
  ) {}

  getAdministrativeScans$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAdministrativeScans),
      mergeMap(() => {
        return this.administrativeScanService.getAdministrativeScans().pipe(
          map(administrativeScans => {
            return getAdministrativeScansSuccess({ administrativeScans });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(getAdministrativeScansFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });
}
