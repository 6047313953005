export interface IPatientContact {
  patientContactRelationshipTypeId: number;
  patientContactRelationship: string;
  firstName: string;
  lastName: string;
  homePhoneNumber: string | null;
  mobilePhoneNumber: string | null;
}

export class PatientContact implements IPatientContact {
  patientContactRelationshipTypeId: number;
  patientContactRelationship: string;
  firstName: string;
  lastName: string;
  homePhoneNumber: string | null;
  mobilePhoneNumber: string | null;

  constructor() {
    this.patientContactRelationshipTypeId = 0;
    this.patientContactRelationship = '';
    this.firstName = '';
    this.lastName = '';
    this.homePhoneNumber = null;
    this.mobilePhoneNumber = null;
  }
}
