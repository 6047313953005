import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { PatientEmployment } from '../../models/patient-employment';
import { getPatientEmployment } from '../../store/actions/patient-employment.actions';
import { selectEmployment } from '../../store/selectors/patient-employment.selectors';
import { PatientEmploymentUiComponent } from '../../ui/patient-employment-ui/patient-employment-ui.component';

@Component({
  selector: 'app-patient-employment',
  standalone: true,
  imports: [CommonModule, LetDirective, ProgressSpinnerComponent, PatientEmploymentUiComponent],
  templateUrl: './patient-employment.component.html',
  styleUrl: './patient-employment.component.scss',
})
export class PatientEmploymentComponent implements OnInit {
  employment$: Observable<PatientEmployment | null>;

  constructor(private store: Store) {
    this.employment$ = this.store.select(selectEmployment);
  }

  ngOnInit(): void {
    this.store.dispatch(getPatientEmployment());
  }

  editEmployment(): void {
    console.log('Edit employment');
  }
}
