/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../../../../environments/environment';
import { appointmentFeatureKey, AppointmentState } from '../reducer/appointment.reducer';

export const appointmentFeatureSelector = createFeatureSelector<AppointmentState>(appointmentFeatureKey);

export const selectIsLoading = createSelector(appointmentFeatureSelector, state => state.isLoading);

export const selectError = createSelector(appointmentFeatureSelector, state =>
  state.error === null ? null : environment.errorMessage
);

export const selectIsSaving = createSelector(appointmentFeatureSelector, state => state.isSaving);

export const selectAppointments = createSelector(appointmentFeatureSelector, state => state.appointments);
