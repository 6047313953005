import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Observable } from 'rxjs';
import { ProgressSpinnerOverlayComponent } from '../../../../../shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { formatDateWithoutTime } from '../../../../../shared/functions/date.functions';
import { Address } from '../../models/address/address';
import { PatientProfile } from '../../models/patient-profile';
import { UsState } from '../../models/state';
import { closeEditDialog, getUsStates, savePatientInformation } from '../../store/actions/patient-information.actions';
import { selectError, selectIsSaving, selectUsStates } from '../../store/selectors/patient-information.selectors';

@Component({
  selector: 'app-edit-patient-information',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LetDirective,
    MatExpansionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMaskDirective,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule,
  ],
  providers: [provideNgxMask(), provideNativeDateAdapter()],
  templateUrl: './edit-patient-information.component.html',
  styleUrl: './edit-patient-information.component.scss',
})
export class EditPatientInformationComponent implements OnInit {
  usStates$: Observable<UsState[] | null>;
  error$: Observable<any>;
  isSaving$: Observable<boolean | null>;
  patientProfile: PatientProfile | null;
  profileForm = new FormGroup({
    firstName: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
    middleInitial: new FormControl<string | null>('', [Validators.maxLength(1)]),
    lastName: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
    dob: new FormControl<Date | null>(null, Validators.required),
    ssn: new FormControl<string>('', Validators.required),
    preferredName: new FormControl<string | null>(null, Validators.maxLength(100)),
    preferredPronouns: new FormControl<string | null>(null),
    mobilePhone: new FormControl<string | null>(null, Validators.required),
    homePhone: new FormControl<string | null>(null),
    residentialAddress: new FormGroup({
      line1: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      line2: new FormControl<string | null>(null, Validators.maxLength(50)),
      city: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      state: new FormControl('', Validators.required),
      zip: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    }),
    mailingAddress: new FormGroup({
      line1: new FormControl<string | null>({ value: null, disabled: false }, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      line2: new FormControl<string | null>({ value: null, disabled: false }, Validators.maxLength(50)),
      city: new FormControl<string | null>({ value: null, disabled: false }, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      state: new FormControl<string | null>({ value: null, disabled: false }, Validators.required),
      zip: new FormControl<string | null>({ value: null, disabled: false }, [
        Validators.required,
        Validators.maxLength(10),
      ]),
    }),
    email: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(100)]),
    primaryCareProvider: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(100)]),
  });
  isSameAsResidential: boolean = false;
  isResidentialExpanded: boolean = true;
  today = new Date();
  yesterday = new Date(this.today).setDate(this.today.getDate() - 1);
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientProfile: PatientProfile | null;
    },
    private store: Store
  ) {
    this.patientProfile = data.patientProfile;
    this.usStates$ = this.store.select(selectUsStates);
    this.error$ = this.store.select(selectError);
    this.isSaving$ = this.store.select(selectIsSaving);

    if (this.patientProfile !== null) {
      this.profileForm.controls['firstName'].patchValue(this.patientProfile.firstName);
      this.profileForm.controls['middleInitial'].patchValue(this.patientProfile.middleInitial);
      this.profileForm.controls['lastName'].patchValue(this.patientProfile.lastName);
      this.profileForm.controls['mobilePhone'].patchValue(this.patientProfile.mobilePhoneNumber);
      this.profileForm.controls['homePhone'].patchValue(this.patientProfile.homePhoneNumber);
      this.profileForm.controls['residentialAddress'].controls['line1'].patchValue(
        this.patientProfile.residentialAddress.address1
      );
      this.profileForm.controls['residentialAddress'].controls['line2'].patchValue(
        this.patientProfile.residentialAddress.address2
      );
      this.profileForm.controls['residentialAddress'].controls['city'].patchValue(
        this.patientProfile.residentialAddress.city
      );
      this.profileForm.controls['residentialAddress'].controls['state'].patchValue(
        this.patientProfile.residentialAddress.stateAbbreviation
      );
      this.profileForm.controls['residentialAddress'].controls['zip'].patchValue(
        this.patientProfile.residentialAddress.postalCode
      );
      if (this.patientProfile.mailingAddress !== null) {
        this.profileForm.controls['mailingAddress'].controls['line1'].patchValue(
          this.patientProfile.mailingAddress.address1
        );
        this.profileForm.controls['mailingAddress'].controls['line2'].patchValue(
          this.patientProfile.mailingAddress.address2
        );
        this.profileForm.controls['mailingAddress'].controls['city'].patchValue(
          this.patientProfile.mailingAddress.city
        );
        this.profileForm.controls['mailingAddress'].controls['state'].patchValue(
          this.patientProfile.mailingAddress.stateAbbreviation
        );
        this.profileForm.controls['mailingAddress'].controls['zip'].patchValue(
          this.patientProfile.mailingAddress.postalCode
        );

        if (
          this.patientProfile.mailingAddress.address1.trim() ===
            this.patientProfile.residentialAddress.address1.trim() &&
          this.patientProfile.mailingAddress.address2?.trim() ===
            this.patientProfile.residentialAddress.address2?.trim() &&
          this.patientProfile.mailingAddress.city.trim() === this.patientProfile.residentialAddress.city.trim() &&
          this.patientProfile.mailingAddress.stateAbbreviation.trim() ===
            this.patientProfile.residentialAddress.stateAbbreviation.trim() &&
          this.patientProfile.mailingAddress.postalCode.trim() ===
            this.patientProfile.residentialAddress.postalCode.trim()
        ) {
          this.toggleIsSameAsResidential(true);
        }
      }
      this.profileForm.controls['email'].patchValue(this.patientProfile.emailAddress);
      this.profileForm.controls['primaryCareProvider'].patchValue(this.patientProfile.primaryCareProviderFullName);
      this.profileForm.controls['preferredName'].patchValue(this.patientProfile.preferredFirstName);
      this.profileForm.controls['preferredPronouns'].patchValue(this.patientProfile.preferredGenderPronouns);
      this.profileForm.controls['ssn'].patchValue(this.patientProfile.socialSecurityNumber);

      const strDate = this.patientProfile.dateOfBirth + ' 12:00:00Z';
      this.profileForm.controls['dob'].patchValue(new Date(strDate));

      if (this.patientProfile.dateOfBirth != null && this.patientProfile.dateOfBirth !== undefined) {
        this.profileForm.controls['dob'].disable();
      }

      if (this.patientProfile.socialSecurityNumber !== null && this.patientProfile.socialSecurityNumber !== '') {
        this.profileForm.controls['ssn'].disable();
      }
    }
  }

  ngOnInit(): void {
    this.store.dispatch(getUsStates());
  }

  toggleIsSameAsResidential(isChecked: boolean) {
    this.isSameAsResidential = isChecked;
    if (isChecked) {
      this.profileForm.controls['mailingAddress'].disable();

      this.profileForm.controls['mailingAddress'].controls['line1'].patchValue(
        this.profileForm.controls['residentialAddress'].controls['line1'].value
      );

      this.profileForm.controls['mailingAddress'].controls['line2'].patchValue(
        this.profileForm.controls['residentialAddress'].controls['line2'].value
      );

      this.profileForm.controls['mailingAddress'].controls['city'].patchValue(
        this.profileForm.controls['residentialAddress'].controls['city'].value
      );

      this.profileForm.controls['mailingAddress'].controls['state'].patchValue(
        this.profileForm.controls['residentialAddress'].controls['state'].value
      );

      this.profileForm.controls['mailingAddress'].controls['zip'].patchValue(
        this.profileForm.controls['residentialAddress'].controls['zip'].value
      );
    } else {
      this.profileForm.controls['mailingAddress'].enable();
      this.profileForm.controls['mailingAddress'].markAllAsTouched();
    }
  }

  save() {
    if (this.profileForm.valid && this.patientProfile !== null) {
      const newProfile = structuredClone(this.patientProfile);
      newProfile.firstName = this.profileForm.controls['firstName'].value!;
      newProfile.middleInitial = this.profileForm.controls['middleInitial'].value!;
      newProfile.lastName = this.profileForm.controls['lastName'].value!;
      newProfile.dateOfBirth = formatDateWithoutTime(this.profileForm.controls['dob'].value)!;
      newProfile.socialSecurityNumber = this.profileForm.controls['ssn'].value!;
      newProfile.mobilePhoneNumber =
        this.profileForm.controls['mobilePhone'].value === null ? null : this.profileForm.controls['mobilePhone'].value;
      newProfile.homePhoneNumber =
        this.profileForm.controls['homePhone'].value === null ? null : this.profileForm.controls['homePhone'].value;
      newProfile.residentialAddress.address1 = this.profileForm.controls['residentialAddress'].controls['line1'].value!;
      newProfile.residentialAddress.address2 = this.profileForm.controls['residentialAddress'].controls['line2'].value;
      newProfile.residentialAddress.city = this.profileForm.controls['residentialAddress'].controls['city'].value!;
      newProfile.residentialAddress.stateAbbreviation =
        this.profileForm.controls['residentialAddress'].controls['state'].value!;
      newProfile.residentialAddress.postalCode = this.profileForm.controls['residentialAddress'].controls['zip'].value!;

      if (this.profileForm.controls['mailingAddress'].controls['line1'].value !== null) {
        if (newProfile.mailingAddress === null) {
          newProfile.mailingAddress = new Address();
        }
        newProfile.mailingAddress.address1 = this.profileForm.controls['mailingAddress'].controls['line1'].value!;
        newProfile.mailingAddress.address2 = this.profileForm.controls['mailingAddress'].controls['line2'].value;
        newProfile.mailingAddress.city = this.profileForm.controls['mailingAddress'].controls['city'].value!;
        newProfile.mailingAddress.stateAbbreviation =
          this.profileForm.controls['mailingAddress'].controls['state'].value!;
        newProfile.mailingAddress.postalCode = this.profileForm.controls['mailingAddress'].controls['zip'].value!;
      }

      newProfile.emailAddress = this.profileForm.controls['email'].value;
      newProfile.primaryCareProviderFullName = this.profileForm.controls['primaryCareProvider'].value;
      newProfile.preferredGenderPronouns = this.profileForm.controls['preferredPronouns'].value;
      newProfile.preferredFirstName = this.profileForm.controls['preferredName'].value;

      this.store.dispatch(savePatientInformation({ profile: newProfile }));
    }
  }

  cancel() {
    this.store.dispatch(closeEditDialog());
  }
}
