import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { UtilityService } from '../../../../../../core/services/utility/utility.service';
import { ReligionType } from '../../../models/types/religion-type';

@Injectable({
  providedIn: 'root',
})
export class ReligionTypesService {
  constructor(private utilityService: UtilityService) {}

  religionTypesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/ReligionTypes';

  getReligionTypes(): Observable<ReligionType[]> {
    return this.utilityService.getRequest<ReligionType[]>(this.religionTypesEndpoint);
  }
}
