import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  title: 'Scheduling:Dev',
  production: false,
  ngxLogger: {
    serverLoggingLevel: NgxLoggerLevel.DEBUG,
    serverLoggingUrl: 'api/log',
    disableConsoleLogging: false,
    consoleLogLevel: NgxLoggerLevel.INFO,
  },
  loginApiUrl: 'https://medone-patientintake-loginapi-eastus-dev.azurewebsites.net/api/v1',
  loginUrl: 'https://patientintake-login-dev.medonesystems.com/login',
  scheduleApiUrl: 'https://medone-patientintake-scheduleapi-eastus-dev.azurewebsites.net',
  pageSize: 10,
  errorMessage: 'Oops something went wrong!',
};
