import { createAction, props } from '@ngrx/store';
import { PatientEmployment } from '../../models/patient-employment';
import { EmploymentStatusType } from '../../models/types/employment-status-type';

export const getPatientEmployment = createAction('[Patient] Get Patient Employment');

export const getPatientEmploymentSuccess = createAction(
  '[Patient] Get Patient Employment Success',
  props<{ employment: PatientEmployment }>()
);

export const getPatientEmploymentFailure = createAction(
  '[Patient] Get Patient Employment Failure',
  props<{ error: any }>()
);

export const getEmploymentStatusTypes = createAction('[Patient] Get Employment Status Types');

export const getEmploymentStatusTypesSuccess = createAction(
  '[Patient] Get Employment Status Types Success',
  props<{ employmentStatusTypes: EmploymentStatusType[] }>()
);

export const getEmploymentStatusTypesFailure = createAction(
  '[Patient] Get Employment Status Types Failure',
  props<{ error: any }>()
);

export const savePatientEmployment = createAction(
  '[Patient] Save Patient Employment',
  props<{ employment: PatientEmployment }>()
);

export const savePatientEmploymentSuccess = createAction(
  '[Patient] Save Patient Employment Success',
  props<{ employment: PatientEmployment }>()
);

export const savePatientEmploymentFailure = createAction(
  '[Patient] Save Patient Employment Failure',
  props<{ error: any }>()
);
