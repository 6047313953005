import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { UtilityService } from '../../../../../../core/services/utility/utility.service';
import { MaritalStatusType } from '../../../models/types/marital-status-type';

@Injectable({
  providedIn: 'root',
})
export class MaritalStatusTypesService {
  constructor(private utilityService: UtilityService) {}

  maritalStatusTypesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/MaritalStatusTypes';

  getMaritalStatusTypes(): Observable<MaritalStatusType[]> {
    return this.utilityService.getRequest<MaritalStatusType[]>(this.maritalStatusTypesEndpoint);
  }
}
