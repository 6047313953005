import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { UtilityService } from '../../../../../../core/services/utility/utility.service';
import { PrimaryLanguageType } from '../../../models/types/primary-language-type';

@Injectable({
  providedIn: 'root',
})
export class PrimaryLanguageTypesService {
  constructor(private utilityService: UtilityService) {}

  primaryLanguageTypesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/PrimaryLanguageTypes';

  getPrimaryLanguageTypes(): Observable<PrimaryLanguageType[]> {
    return this.utilityService.getRequest<PrimaryLanguageType[]>(this.primaryLanguageTypesEndpoint);
  }
}
