<div class="layout-header-container">
  <app-header
    [locations]="locations$ | async"
    [selectedLocationNameId]="selectedLocationNameId$ | async"
    (selectLocationNameIdEvent)="onSelectLocationNameId($event)"
    (openSchedulingTipsEvent)="openSchedulingTips($event)"></app-header>

  <app-user-menu class="mr-20"></app-user-menu>
</div>

<app-side-nav></app-side-nav>
