<div class="content-title">
  <div class="ml-20 mr-20 flex justify-center align-center">
    <h3 class="align-center flex-2 text-center">Tips for {{ locationName }}</h3>
    <span
      id="btnClose"
      class="material-symbols-outlined cursor fs-32 color-neutral"
      tabindex="0"
      (click)="close()"
      (keyup)="close()"
      >close</span
    >
  </div>
</div>

<div id="content" *ngrxLet="{ schedulingTips: schedulingTips$, isLoading: isLoading$, error: error$ } as content">
  @if (content.isLoading) {
    <div class="flex justify-center align-center mt-10 mb-10">
      <app-progress-spinner [diameter]="100"></app-progress-spinner>
    </div>
  } @else if (content.schedulingTips !== null && content.schedulingTips.notes !== null) {
    <div id="tips-txt" class="m-20">{{ content.schedulingTips.notes }}</div>
  } @else if (content.error === null) {
    <div id="no-tips-txt" class="m-20">No scheduling tips for this location</div>
  } @else {
    <div id="error-txt" class="m-20">Error loading scheduling tips</div>
  }
</div>
