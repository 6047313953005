import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { UsState } from '../../models/state';

@Injectable({
  providedIn: 'root',
})
export class UsStatesService {
  constructor(private utilityService: UtilityService) {}

  usStatesEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/States';

  getUsStates(): Observable<UsState[]> {
    return this.utilityService.getRequest<UsState[]>(this.usStatesEndpoint);
  }
}
