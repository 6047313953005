<div class="tile-container">
  <app-patient-information class="tile tile-large"></app-patient-information>

  <app-patient-contacts class="tile tile-large"></app-patient-contacts>

  <app-patient-demographics class="tile tile-small"></app-patient-demographics>

  <app-patient-employment class="tile tile-small"></app-patient-employment>

  <app-appointment class="tile tile-xl"></app-appointment>

  <app-administrative-scan class="tile tile-xl"></app-administrative-scan>
</div>
