<div class="contact-header-container">
  <div class="contact-header">
    <h4>Emergency Contacts</h4>
    @if (contacts !== null && (contacts.primaryContact !== null || contacts.secondaryContact !== null)) {
      <button id="btnEdit" type="button" data-test="btnEditContacts" mat-stroked-button (click)="editEvent.emit()">
        Edit
      </button>
    }
  </div>
</div>

@if (contacts !== null) {
  @if (contacts.primaryContact === null && contacts.secondaryContact === null) {
    <div class="flex justify-center align-center mt-40">
      <button
        id="btnAdd"
        type="button"
        data-test="btnAddContacts"
        mat-stroked-button
        class="add-button"
        (click)="editEvent.emit()">
        Add Emergency Contact
      </button>
    </div>
  } @else {
    @if (contacts.primaryContact !== null) {
      <div class="contact-container">
        <h4 id="pContactName" data-test="strPrimaryFirstLast">
          {{ contacts.primaryContact.firstName }} {{ contacts.primaryContact.lastName }}
          <span class="fw-400 fs-14">(primary)</span>
        </h4>

        <div class="row-container">
          <div class="flex align-start gap-8">
            <mat-icon fontIcon="smartphone" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
            <div class="flex flex-col gap-4">
              <p class="mat-label-medium">Mobile</p>
              @if (
                contacts.primaryContact.mobilePhoneNumber !== null && contacts.primaryContact.mobilePhoneNumber !== ''
              ) {
                <p id="pMobilePhone" class="fw-500" data-test="strPrimaryMobilePhoneNumber">
                  {{ contacts.primaryContact.mobilePhoneNumber | mask: '(000) 000-0000' }}
                </p>
              } @else {
                <p id="pMobilePhoneNA" class="fw-500" data-test="strPrimaryMobilePhoneNumberNA">Not Available</p>
              }
            </div>
          </div>

          <div class="flex align-start gap-8">
            <mat-icon fontIcon="phone" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
            <div class="flex flex-col gap-4">
              <p class="mat-label-medium">Home</p>
              @if (contacts.primaryContact.homePhoneNumber !== null && contacts.primaryContact.homePhoneNumber !== '') {
                <p id="pHomePhone" class="fw-500" data-test="strPrimaryHomePhoneNumber">
                  {{ contacts.primaryContact.homePhoneNumber | mask: '(000) 000-0000' }}
                </p>
              } @else {
                <p id="pHomePhoneNA" class="fw-500" data-test="strPrimaryHomePhoneNumberNA">Not Available</p>
              }
            </div>
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="digital_wellbeing" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Relationship</p>
            <p id="pRelationship" class="fw-500" data-test="strPrimaryRelationship">
              {{ contacts.primaryContact.patientContactRelationship }}
            </p>
          </div>
        </div>
      </div>
    }
    @if (contacts.secondaryContact !== null) {
      <div id="secondaryDivider" class="divider"></div>
      <div id="secondaryContact" class="contact-container">
        <h4 id="sContactName" data-test="strSecondaryFirstLast">
          {{ contacts.secondaryContact.firstName }} {{ contacts.secondaryContact.lastName }}
          <span class="fw-400 fs-14">(secondary)</span>
        </h4>

        <div class="row-container">
          <div class="flex align-start gap-8">
            <mat-icon fontIcon="smartphone" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
            <div class="flex flex-col gap-4">
              <p class="mat-label-medium">Mobile</p>
              @if (
                contacts.secondaryContact.mobilePhoneNumber !== null &&
                contacts.secondaryContact.mobilePhoneNumber !== ''
              ) {
                <p id="sMobilePhone" class="fw-500" data-test="strSecondaryMobilePhoneNumber">
                  {{ contacts.secondaryContact.mobilePhoneNumber | mask: '(000) 000-0000' }}
                </p>
              } @else {
                <p id="sMobilePhoneNA" class="fw-500" data-test="strSecondaryMobilePhoneNumberNA">Not Available</p>
              }
            </div>
          </div>

          <div class="flex align-start gap-8">
            <mat-icon fontIcon="phone" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
            <div class="flex flex-col gap-4">
              <p class="mat-label-medium">Home</p>
              @if (
                contacts.secondaryContact.homePhoneNumber !== null && contacts.secondaryContact.homePhoneNumber !== ''
              ) {
                <p id="sHomePhone" class="fw-500" data-test="strSecondaryHomePhoneNumber">
                  {{ contacts.secondaryContact.homePhoneNumber | mask: '(000) 000-0000' }}
                </p>
              } @else {
                <p id="sHomePhoneNA" class="fw-500" data-test="strSecondaryHomePhoneNumberNA">Not Available</p>
              }
            </div>
          </div>
        </div>

        <div class="flex align-start gap-8">
          <mat-icon fontIcon="digital_wellbeing" class="color-neutral material-symbols-outlined fs-18"></mat-icon>
          <div class="flex flex-col gap-4">
            <p class="mat-label-medium">Relationship</p>
            <p id="sRelationship" class="fw-500" data-test="strSecondaryRelationship">
              {{ contacts.secondaryContact.patientContactRelationship }}
            </p>
          </div>
        </div>
      </div>
    }
  }
}
