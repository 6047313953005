<ng-container
  *ngrxLet="{
    demographics: demographics$,
  } as vm">
  <app-progress-spinner
    *ngIf="vm.demographics === null; else demographicsDiv"
    data-test="spinnerPatientDemographics"
    class="loading"></app-progress-spinner>

  <ng-template #demographicsDiv>
    <app-patient-demographics-ui
      [demographics]="vm.demographics"
      (editEvent)="editDemographics()"></app-patient-demographics-ui>
  </ng-template>
</ng-container>
