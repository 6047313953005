import { Appointment } from '../../app/patient/patient-profile/appointment/models/appointment';

export const mockAppointments: Appointment[] = [
  {
    appointmentReason: 'Checkup',
    appointmentDate: '2024-12-1',
    appointmentLocation: 'Cleveland Clinic',
    appointmentProvider: 'Dr. Patel',
  },
  {
    appointmentReason: 'Follow-up High BP and weight gain',
    appointmentDate: '2024-12-5',
    appointmentLocation: 'Dept of Primary Care Dr Richards',
    appointmentProvider: 'Dr. Richards',
  },
  {
    appointmentReason: 'Tonsil Removal',
    appointmentDate: '2024-12-5',
    appointmentLocation: 'Marietta Medical',
    appointmentProvider: 'Dr. Brown',
  },
  {
    appointmentReason: 'Consultation',
    appointmentDate: '2024-12-12',
    appointmentLocation: 'Ohio Cardiology',
    appointmentProvider: 'Dr. Reginald',
  },
  {
    appointmentReason: 'Checkup',
    appointmentDate: '2024-12-14',
    appointmentLocation: 'Cleveland Endoscopy',
    appointmentProvider: 'Dr. Mane',
  },
  {
    appointmentReason: 'Diagnostics',
    appointmentDate: '2024-12-17',
    appointmentLocation: 'Cleveland University of Diagnostic Diagnoses',
    appointmentProvider: 'Dr. Real',
  },
  {
    appointmentReason: 'Mole Removal',
    appointmentDate: '2024-12-20',
    appointmentLocation: 'Denver Dermatology',
    appointmentProvider: 'Dr. Abraholywhatalongnamethisis',
  },
  {
    appointmentReason: 'Upper Body Rash',
    appointmentDate: '2024-12-22',
    appointmentLocation: 'Denver Dermatology',
    appointmentProvider: 'Dr. Patel',
  },
  {
    appointmentReason: 'Freckle Counting',
    appointmentDate: '2024-12-27',
    appointmentLocation: 'Denver Dermatology',
    appointmentProvider: 'Dr. Fricklecounter',
  },
  {
    appointmentReason: "New Year's Eve Party",
    appointmentDate: '2024-12-31',
    appointmentLocation: 'Cleveland Clinic',
    appointmentProvider: 'Dr. Patel',
  },
];
