<div class="edit-profile-header-container">
  <div class="edit-profile-header">
    <div class="flex justify-center align-center flex-1">
      <h4>Edit Patient Information</h4>
    </div>
    <mat-icon
      id="btnClose"
      data-test="btnCloseEditProfile"
      fontIcon="close"
      class="material-symbols-outlined color-neutral cursor"
      (click)="cancel()"></mat-icon>
  </div>
</div>

<ng-container *ngrxLet="{ usStates: usStates$, isSaving: isSaving$ } as vm">
  <app-progress-spinner
    *ngIf="vm.usStates === null || patientProfile === null; else patientInfoForm"
    data-test="spinnerEditPatientProfile"></app-progress-spinner>

  <ng-template #patientInfoForm>
    <app-progress-spinner-overlay
      [displayProgressSpinner]="vm.isSaving"
      data-test="spinnerSavePatientProfile"></app-progress-spinner-overlay>

    <div id="editProfileForm" class="edit-profile-container">
      <form [formGroup]="profileForm" (submit)="save()">
        <div class="form-button-container">
          <div class="form-container">
            <div class="flex w-100p">
              <div class="form-field mr-20 col-xl">
                <mat-label>First Name <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input matInput type="text" formControlName="firstName" data-test="iptFirstName" />
                  @if (profileForm.controls['firstName'].hasError('required')) {
                    <mat-error id="errFirstNameRequired" data-test="matErrFirstNameRequired">Required</mat-error>
                  } @else if (profileForm.controls['firstName'].hasError('maxlength')) {
                    <mat-error id="errFirstNameMax" data-test="matErrFirstNameMax"
                      >Must be 100 characters or less</mat-error
                    >
                  }
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-xs">
                <mat-label>Middle Initial</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    type="text"
                    maxlength="1"
                    formControlName="middleInitial"
                    data-test="iptMiddleInitial" />
                  @if (profileForm.controls['middleInitial'].hasError('maxlength')) {
                    <mat-error id="errMiddleInitialMax" data-test="matErrMiddleInitialMax"
                      >Must be 1 character</mat-error
                    >
                  }
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-xl">
                <mat-label>Last Name <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input matInput type="text" formControlName="lastName" data-test="iptLastName" />
                  @if (profileForm.controls['lastName'].hasError('required')) {
                    <mat-error id="errLastNameRequired" data-test="matErrLastNameRequired">Required</mat-error>
                  } @else if (profileForm.controls['lastName'].hasError('maxlength')) {
                    <mat-error id="errLastNameMax" data-test="matErrLastNameMax"
                      >Must be 100 characters or less</mat-error
                    >
                  }
                </mat-form-field>
              </div>
            </div>

            <div class="flex w-100p">
              <div class="form-field mr-20 col-lg">
                <mat-label>Preferred First Name</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input matInput type="text" formControlName="preferredName" data-test="iptPreferredName" />
                  @if (profileForm.controls['preferredName'].hasError('maxlength')) {
                    <mat-error id="errPreferredFirstNameMax" data-test="matErrPreferredFirstNameMax"
                      >Must be 100 characters or less</mat-error
                    >
                  }
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-lg">
                <mat-label>Date of Birth <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="flex">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    id="dateOfBirth"
                    formControlName="dob"
                    data-test="dteDateOfBirth"
                    [max]="yesterday" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  @if (profileForm.controls['dob'].hasError('required')) {
                    <mat-error id="errDateOfBirthRequired">Required</mat-error>
                  } @else if (profileForm.controls['dob'].hasError('matDatepickerMax')) {
                    <mat-error id="errDateOfBirthInvalid">Date must be in the past</mat-error>
                  }
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-lg">
                <mat-label>Social Security Number <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    type="text"
                    id="socialSecurityNumber"
                    formControlName="ssn"
                    data-test="iptSocialSecurityNumber"
                    mask="000-00-0000" />
                  @if (profileForm.controls['ssn'].hasError('required')) {
                    <mat-error id="errSocialSecurityNumberRequired" data-test="matErrSocialSecurityNumberRequired"
                      >Required</mat-error
                    >
                  } @else if (profileForm.controls['ssn'].hasError('mask')) {
                    <mat-error id="errSocialSecurityNumberInvalid" data-test="errSocialSecurityNumberInvalid"
                      >Must be 9 digits</mat-error
                    >
                  }
                </mat-form-field>
              </div>
            </div>

            <div class="flex w-100p">
              <div class="form-field mr-20 col-lg">
                <mat-label>Preferred Pronouns</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select formControlName="preferredPronouns" data-test="selPreferredPronouns">
                    <mat-option value="He/Him">He/Him</mat-option>
                    <mat-option value="She/Her">She/Her</mat-option>
                    <mat-option value="They/Them">They/Them</mat-option>
                    <mat-option value="Ze/Zir">Ze/Zir</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-lg">
                <mat-label>Mobile Phone <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    id="mobilePhone"
                    type="tel"
                    matInput
                    formControlName="mobilePhone"
                    data-test="iptMobilePhone"
                    mask="(000) 000-0000" />
                  @if (profileForm.controls['mobilePhone'].hasError('required')) {
                    <mat-error id="errMobilePhoneRequired" data-test="matErrMobilePhoneRequired">Required</mat-error>
                  } @else if (profileForm.controls['mobilePhone'].hasError('mask')) {
                    <mat-error id="errMobilePhoneLength" data-test="matErrMobilePhoneLength"
                      >Must be 10 digits</mat-error
                    >
                  }
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-lg">
                <mat-label>Home Phone</mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    id="homePhone"
                    type="tel"
                    matInput
                    formControlName="homePhone"
                    data-test="iptHomePhone"
                    mask="(000) 000-0000" />
                  @if (profileForm.controls['homePhone'].hasError('mask')) {
                    <mat-error id="errHomePhoneLength" data-test="matErrHomePhoneLength">Must be 10 digits</mat-error>
                  }
                </mat-form-field>
              </div>
            </div>

            <div class="flex w-100p">
              <div class="form-field mr-20 col-xl">
                <mat-label>Email <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input matInput type="text" formControlName="email" data-test="iptEmail" />
                  @if (profileForm.controls['email'].hasError('required')) {
                    <mat-error id="errEmailRequired" data-test="matErrEmailRequired">Required</mat-error>
                  } @else if (profileForm.controls['email'].hasError('maxlength')) {
                    <mat-error id="errEmailMax" data-test="matErrEmailMax">Must be 100 characters or less</mat-error>
                  }
                </mat-form-field>
              </div>

              <div class="form-field mr-20 col-lg">
                <mat-label>Primary Care Physician <span class="color-warn">*</span></mat-label>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    type="text"
                    formControlName="primaryCareProvider"
                    data-test="iptPrimaryCareProvider" />
                  @if (profileForm.controls['primaryCareProvider'].hasError('required')) {
                    <mat-error id="errPrimaryCareProviderRequired" data-test="matErrPrimaryCareProviderRequired"
                      >Required</mat-error
                    >
                  } @else if (profileForm.controls['primaryCareProvider'].hasError('maxlength')) {
                    <mat-error id="errPrimaryCareProviderMax" data-test="matErrPrimaryCareProviderMax"
                      >Must be 100 characters or less</mat-error
                    >
                  }
                </mat-form-field>
              </div>
            </div>

            <mat-accordion id="addresses" data-test="accordAddresses">
              <mat-expansion-panel id="residentialAddress" expanded="true" data-test="epnlResidentialAddress">
                <mat-expansion-panel-header class="expansion-header">
                  <mat-panel-title>Residential Address</mat-panel-title>
                </mat-expansion-panel-header>

                <div formGroupName="residentialAddress" class="form-container">
                  <div class="flex w-100p">
                    <div class="form-field mr-20 address-col-xl">
                      <mat-label>Street Address <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          matInput
                          type="text"
                          formControlName="line1"
                          data-test="iptResidentialAddressLine1"
                          (ngModelChange)="
                            isSameAsResidential
                              ? this.profileForm.controls['mailingAddress'].controls['line1'].patchValue($event)
                              : false
                          " />
                        @if (profileForm.controls['residentialAddress'].controls['line1'].hasError('required')) {
                          <mat-error id="errResidentialStreetRequired" data-test="matErrResidentialStreetRequired"
                            >Required</mat-error
                          >
                        } @else if (
                          profileForm.controls['residentialAddress'].controls['line1'].hasError('maxlength')
                        ) {
                          <mat-error id="errResidentialStreetMax" data-test="matErrResidentialStreetMax"
                            >Must be 100 characters or less</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="form-field address-col-md">
                      <mat-label>Building/Suite #</mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          matInput
                          type="text"
                          formControlName="line2"
                          data-test="iptResidentialAddressLine2"
                          (ngModelChange)="
                            isSameAsResidential
                              ? this.profileForm.controls['mailingAddress'].controls['line2'].patchValue($event)
                              : false
                          " />
                        @if (profileForm.controls['residentialAddress'].controls['line2'].hasError('maxlength')) {
                          <mat-error id="errResidentialSuiteMax" data-test="matErrResidentialSuiteMax"
                            >Must be 50 characters or less</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex gap-20">
                    <div class="form-field address-col-xl">
                      <mat-label>City <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          matInput
                          type="text"
                          formControlName="city"
                          data-test="iptResidentialAddressCity"
                          (ngModelChange)="
                            isSameAsResidential
                              ? this.profileForm.controls['mailingAddress'].controls['city'].patchValue($event)
                              : false
                          " />
                        @if (profileForm.controls['residentialAddress'].controls['city'].hasError('required')) {
                          <mat-error id="errResidentialCityRequired" data-test="matErrResidentialCityRequired"
                            >Required</mat-error
                          >
                        } @else if (profileForm.controls['residentialAddress'].controls['city'].hasError('maxlength')) {
                          <mat-error id="errResidentialCityMax" data-test="matErrResidentialCityMax"
                            >Must be 100 characters or less</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="form-field address-col-md">
                      <mat-label>State <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-select
                          formControlName="state"
                          data-test="selResidentialAddressState"
                          (ngModelChange)="
                            isSameAsResidential
                              ? this.profileForm.controls['mailingAddress'].controls['state'].patchValue($event)
                              : false
                          ">
                          @for (state of (usStates$ | async) || []; track state) {
                            <mat-option [value]="state.abbreviation">{{ state.name }}</mat-option>
                          }
                        </mat-select>
                        @if (profileForm.controls['residentialAddress'].controls['state'].hasError('required')) {
                          <mat-error id="errResidentialStateRequired" data-test="matErrResidentialStateRequired"
                            >Required</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex gap-20">
                    <div class="form-field address-col-sm">
                      <mat-label>Zip <span class="color-warn">*</span></mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          matInput
                          type="text"
                          formControlName="zip"
                          data-test="iptResidentialAddressPostalCode"
                          pattern="^[0-9]{5}(?:-[0-9]{4})?$"
                          mask="00000-0000"
                          [validation]="false"
                          [dropSpecialCharacters]="false"
                          (ngModelChange)="
                            isSameAsResidential
                              ? this.profileForm.controls['mailingAddress'].controls['zip'].patchValue($event)
                              : false
                          " />
                        @if (profileForm.controls['residentialAddress'].controls['zip'].hasError('required')) {
                          <mat-error id="errResidentialZipRequired" data-test="matErrResidentialZipRequired"
                            >Required</mat-error
                          >
                        } @else if (profileForm.controls['residentialAddress'].controls['zip'].hasError('pattern')) {
                          <mat-error
                            id="errResidentialAddressPostalCodeLength"
                            data-test="matErrResidentialAddressPostalCodeLength"
                            >Invalid postal code</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel id="mailingAddress" data-test="epnlMailingAddress">
                <mat-expansion-panel-header class="expansion-header">
                  <mat-panel-title>Mailing Address</mat-panel-title>
                  <mat-panel-description>
                    <mat-checkbox
                      class="ml-n10"
                      (change)="toggleIsSameAsResidential($event.checked)"
                      (click)="$event.stopPropagation()"
                      [checked]="isSameAsResidential"
                      data-test="cbxSameAsResidential"
                      ><span class="fw-600 fs-14 color-neutral">Same As Residential</span></mat-checkbox
                    >
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div formGroupName="mailingAddress" class="form-container">
                  <div class="flex w-100p">
                    <div class="form-field mr-20 address-col-xl">
                      <mat-label
                        >Street Address
                        @if (!isSameAsResidential) {
                          <span class="color-warn">*</span>
                        }
                      </mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input matInput type="text" formControlName="line1" data-test="iptMailingAddressLine1" />
                        @if (profileForm.controls['mailingAddress'].controls['line1'].hasError('required')) {
                          <mat-error id="errMailingStreetRequired" data-test="matErrMailingStreetRequired"
                            >Required</mat-error
                          >
                        } @else if (profileForm.controls['mailingAddress'].controls['line1'].hasError('maxlength')) {
                          <mat-error id="errMailingStreetMax" data-test="matErrMailingStreetMax"
                            >Must be 100 characters or less</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="form-field address-col-md">
                      <mat-label>Building/Suite #</mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input matInput type="text" formControlName="line2" data-test="iptMailingAddressLine2" />
                        @if (profileForm.controls['mailingAddress'].controls['line2'].hasError('maxlength')) {
                          <mat-error id="errMailingSuiteMax" data-test="matErrMailingSuiteMax"
                            >Must be 50 characters or less</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex gap-20">
                    <div class="form-field address-col-xl">
                      <mat-label
                        >City
                        @if (!isSameAsResidential) {
                          <span class="color-warn">*</span>
                        }
                      </mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input matInput type="text" formControlName="city" data-test="iptMailingAddressCity" />
                        @if (profileForm.controls['mailingAddress'].controls['city'].hasError('required')) {
                          <mat-error id="errMailingCityRequired" data-test="matErrMailingCityRequired"
                            >Required</mat-error
                          >
                        } @else if (profileForm.controls['mailingAddress'].controls['city'].hasError('maxlength')) {
                          <mat-error id="errMailingCityMax" data-test="matErrMailingCityMax"
                            >Must be 100 characters or less</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>

                    <div class="form-field address-col-md">
                      <mat-label
                        >State
                        @if (!isSameAsResidential) {
                          <span class="color-warn">*</span>
                        }
                      </mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-select formControlName="state" data-test="selMailingAddressState">
                          @for (state of (usStates$ | async) || []; track state) {
                            <mat-option [value]="state.abbreviation">{{ state.name }}</mat-option>
                          }
                        </mat-select>
                        @if (profileForm.controls['mailingAddress'].controls['state'].hasError('required')) {
                          <mat-error id="errMailingStateRequired" data-test="matErrMailingStateRequired"
                            >Required</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="flex gap-20">
                    <div class="form-field address-col-sm">
                      <mat-label
                        >Zip
                        @if (!isSameAsResidential) {
                          <span class="color-warn">*</span>
                        }
                      </mat-label>
                      <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <input
                          matInput
                          type="text"
                          formControlName="zip"
                          data-test="iptMailingAddressPostalCode"
                          pattern="^[0-9]{5}(?:-[0-9]{4})?$"
                          mask="00000-0000"
                          [validation]="false"
                          [dropSpecialCharacters]="false" />
                        @if (profileForm.controls['mailingAddress'].controls['zip'].hasError('required')) {
                          <mat-error id="errMailingZipRequired" data-test="matErrMailingZipRequired"
                            >Required</mat-error
                          >
                        } @else if (profileForm.controls['mailingAddress'].controls['zip'].hasError('pattern')) {
                          <mat-error
                            id="errMailingAddressPostalCodeLength"
                            data-test="matErrMailingAddressPostalCodeLength"
                            >Invalid postal code</mat-error
                          >
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="button-container">
            <button
              id="btnCancel"
              data-test="btnCancelEditProfile"
              mat-stroked-button
              type="button"
              class="cancel-button"
              (click)="cancel()">
              Cancel
            </button>
            <button
              id="btnSave"
              data-test="btnSaveEditProfile"
              mat-raised-button
              type="submit"
              class="save-button"
              color="primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</ng-container>
