import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { UtilityService } from '../../../../../core/services/utility/utility.service';
import { PatientDemographics } from '../../models/patient-demographics';

@Injectable({
  providedIn: 'root',
})
export class PatientDemographicsService {
  constructor(private utilityService: UtilityService) {}

  patientDemographicsEndpoint = environment.scheduleApiUrl + '/patientApi/Admin/PatientDemographics';

  getPatientDemographics(): Observable<PatientDemographics> {
    const url = this.patientDemographicsEndpoint + '?patientId=28';

    return this.utilityService.getRequest<PatientDemographics>(url);
  }

  postPatientDemographics(demographics: PatientDemographics): Observable<PatientDemographics> {
    const body = {
      patientId: 28,
      maritalStatusTypeId: demographics.maritalStatusTypeId,
      primaryLanguageTypeId: demographics.primaryLanguageTypeId,
      raceTypeId: demographics.raceTypeId,
      ethnicityTypeId: demographics.ethnicityTypeId,
      religionTypeId: demographics.religionTypeId,
      version: demographics.version,
      modifiedByUserId: 1,
    };

    return this.utilityService.postRequest<PatientDemographics>(this.patientDemographicsEndpoint, body);
  }
}
