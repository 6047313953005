<ng-container *ngrxLet="{ appointments: appointments$ } as vm">
  <app-progress-spinner
    *ngIf="vm.appointments.length === 0; else appointmentDiv"
    data-test="spinnerAppointment"
    class="loading"></app-progress-spinner>

  <ng-template #appointmentDiv>
    <app-appointment-ui [appointments]="vm.appointments"></app-appointment-ui>
  </ng-template>
</ng-container>
