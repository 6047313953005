import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerOverlayComponent } from '../../../../../shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from '../../../../../shared/component/progress-spinner/progress-spinner.component';
import { PatientDemographics } from '../../models/patient-demographics';
import { EthnicityType } from '../../models/types/ethnicity-types';
import { MaritalStatusType } from '../../models/types/marital-status-type';
import { PrimaryLanguageType } from '../../models/types/primary-language-type';
import { RaceType } from '../../models/types/race-type';
import { ReligionType } from '../../models/types/religion-type';
import {
  closeEditDialog,
  editPatientDemographicsComponentLoaded,
  savePatientDemographics,
} from '../../store/actions/patient-demographics.actions';
import {
  selectEthnicityTypes,
  selectIsSaving,
  selectMaritalStatusTypes,
  selectPrimaryLanguageTypes,
  selectRaceTypes,
  selectReligionTypes,
} from '../../store/selectors/patient-demographics.selectors';

@Component({
  selector: 'app-edit-patient-demographics',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LetDirective,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './edit-patient-demographics.component.html',
  styleUrl: './edit-patient-demographics.component.scss',
})
export class EditPatientDemographicsComponent implements OnInit {
  maritalStatusTypes$: Observable<MaritalStatusType[] | null>;
  raceTypes$: Observable<RaceType[] | null>;
  ethnicityTypes$: Observable<EthnicityType[] | null>;
  primaryLanguageTypes$: Observable<PrimaryLanguageType[] | null>;
  religionTypes$: Observable<ReligionType[] | null>;
  isSaving$: Observable<boolean | null>;

  patientDemographics: PatientDemographics | null;

  demographicsForm = new FormGroup({
    maritalStatus: new FormControl<number | null>(null),
    primaryLanguage: new FormControl<number | null>(null),
    race: new FormControl<number | null>(null),
    ethnicity: new FormControl<number | null>(null),
    religion: new FormControl<number | null>(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientDemographics: PatientDemographics | null;
    },
    private store: Store
  ) {
    this.maritalStatusTypes$ = this.store.select(selectMaritalStatusTypes);
    this.raceTypes$ = this.store.select(selectRaceTypes);
    this.ethnicityTypes$ = this.store.select(selectEthnicityTypes);
    this.primaryLanguageTypes$ = this.store.select(selectPrimaryLanguageTypes);
    this.religionTypes$ = this.store.select(selectReligionTypes);
    this.isSaving$ = this.store.select(selectIsSaving);
    this.patientDemographics = data.patientDemographics;

    if (this.patientDemographics !== null) {
      this.demographicsForm.controls['maritalStatus'].patchValue(this.patientDemographics.maritalStatusTypeId);
      this.demographicsForm.controls['primaryLanguage'].patchValue(this.patientDemographics.primaryLanguageTypeId);
      this.demographicsForm.controls['race'].patchValue(this.patientDemographics.raceTypeId);
      this.demographicsForm.controls['ethnicity'].patchValue(this.patientDemographics.ethnicityTypeId);
      this.demographicsForm.controls['religion'].patchValue(this.patientDemographics.religionTypeId);
    }
  }

  ngOnInit(): void {
    this.store.dispatch(editPatientDemographicsComponentLoaded());
  }

  save() {
    if (this.patientDemographics !== null) {
      const newDemographics = structuredClone(this.patientDemographics);
      newDemographics.maritalStatusTypeId = this.demographicsForm.controls['maritalStatus'].value;
      newDemographics.maritalStatus = null;
      newDemographics.primaryLanguageTypeId = this.demographicsForm.controls['primaryLanguage'].value;
      newDemographics.primaryLanguage = null;
      newDemographics.raceTypeId = this.demographicsForm.controls['race'].value;
      newDemographics.race = null;
      newDemographics.ethnicityTypeId = this.demographicsForm.controls['ethnicity'].value;
      newDemographics.ethnicity = null;
      newDemographics.religionTypeId = this.demographicsForm.controls['religion'].value;
      newDemographics.religion = null;
      this.store.dispatch(savePatientDemographics({ demographics: newDemographics }));
    }
  }

  cancel() {
    this.store.dispatch(closeEditDialog());
  }
}
