<ng-container *ngrxLet="{ employment: employment$ } as vm">
  <app-progress-spinner
    *ngIf="vm.employment === null; else employmentDiv"
    data-test="spinnerPatientEmployment"
    class="loading"></app-progress-spinner>

  <ng-template #employmentDiv>
    <app-patient-employment-ui [employment]="vm.employment" (editEvent)="editEmployment()"></app-patient-employment-ui>
  </ng-template>
</ng-container>
